import {useAddressAutocomplete} from '@/hooks/useAddressAutocomplete';
import {AddressLookupItem} from '@adyen/adyen-web/dist/types/components/internal/Address/types';
import {Trans, t} from '@lingui/macro';
import {GetCheckoutV2Output} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {
  InputSelect,
  InputText,
  Label,
  Typeahead,
  Typography,
  ValidationError,
  useToggle,
} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import {InitiateAdyenCustomCardComponentFunction} from '../adyen-components/use-adyen-custom-card';
import {CheckoutV2FormData, checkoutSupportedCountries} from '../checkout-schema';

type SchemePaymentMethodFormProps = {
  form: UseFormReturn<CheckoutV2FormData>;
  checkout: GetCheckoutV2Output;
  initiateAdyenCustomCardComponent: InitiateAdyenCustomCardComponentFunction;
};

export const SchemePaymentMethodForm = ({
  checkout,
  form,
  initiateAdyenCustomCardComponent,
}: SchemePaymentMethodFormProps) => {
  const {
    register,
    formState: {errors},
  } = form;

  useEffect(() => {
    if (!checkout) {
      return;
    }

    const domNode = document.getElementById('customCard-container');
    if (!domNode) {
      return;
    }

    initiateAdyenCustomCardComponent(domNode, checkout, setCardErrors);
  }, [checkout, initiateAdyenCustomCardComponent]);

  const [cardErrors, setCardErrors] = useState<{
    encryptedCardNumber: {message: string} | null;
    encryptedExpiryDate: {message: string} | null;
    encryptedSecurityCode: {message: string} | null;
  }>({
    encryptedCardNumber: null,
    encryptedExpiryDate: null,
    encryptedSecurityCode: null,
  });

  return (
    <div id="customCard-container">
      <Label className="mt-2" text={t`Card Number`}>
        <div className="h-10 text-gray-400 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm">
          <span data-cse="encryptedCardNumber" />
        </div>
        <ValidationError isVisible={Boolean(cardErrors.encryptedCardNumber)}>
          {cardErrors.encryptedCardNumber?.message}
        </ValidationError>
      </Label>
      <div className="flex flex-row items-stretch gap-x-4 gap-y-4">
        <Label className="mt-2" text={t`Expiry Date`}>
          <div className="h-10 text-gray-400 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm">
            <span data-cse="encryptedExpiryDate" />
          </div>
          <ValidationError isVisible={Boolean(cardErrors.encryptedExpiryDate)}>
            {cardErrors.encryptedExpiryDate?.message}
          </ValidationError>
        </Label>
        <Label className="mt-2" text={t`Security Code`}>
          <div className="h-10 text-gray-400 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm">
            <span data-cse="encryptedSecurityCode" />
          </div>
          <ValidationError isVisible={Boolean(cardErrors.encryptedSecurityCode)}>
            {cardErrors.encryptedSecurityCode?.message}
          </ValidationError>
        </Label>
      </div>
      <Typography variant="header-form-section" className="mt-8">
        <Trans>Cardholder</Trans>
      </Typography>
      <Label className="mt-2" text={t`Full Name`}>
        <InputText
          {...register('paymentMethod.scheme.holderName', {
            required: true,
          })}
          placeholder="John Doe"
          containerClassName="h-10"
          inputClassName="sm:text-base"
        />
        <ValidationError isVisible={Boolean(errors.paymentMethod?.scheme?.holderName?.message)}>
          {errors.paymentMethod?.scheme?.holderName?.message}
        </ValidationError>
      </Label>
      <CheckoutBillingAddress checkout={checkout} form={form} />
    </div>
  );
};

type CheckoutBillingAddressProps = {
  form: UseFormReturn<CheckoutV2FormData>;
  checkout: GetCheckoutV2Output;
};
const CheckoutBillingAddress = ({form, checkout}: CheckoutBillingAddressProps) => {
  const {initialize: initGoogleMapsApi, getAddressPredictions} = useAddressAutocomplete(
    checkoutSupportedCountries
  );
  const [predictions, setPredictions] = useState<AddressLookupItem[]>([]);
  const [isBillingAddressSectionShown, showAddressSection] = useToggle(false);

  const {
    register,
    setValue,
    control,
    formState: {errors},
  } = form;
  console.log('errors', errors, checkout.billingAddressMode);

  const handleAddressLineChange = async (address: string) => {
    setValue('paymentMethod.scheme.fullBillingAddress.searchAddress', address);
    if (address.length === 0) return;
    const predictionItems = await getAddressPredictions(address);
    setPredictions(predictionItems);
  };

  const onSelectAddress = (value: string, onChange: (v: string) => void) => {
    const addressId = Array.isArray(value) ? value[0] : value;
    const address = predictions.find(prediction => prediction.id === addressId);
    if (!address) {
      onChange(value);
      return;
    }
    onChange('');
    showAddressSection();
    setValue('paymentMethod.scheme.fullBillingAddress.country', address.country || '', {
      shouldValidate: true,
    });
    setValue('paymentMethod.scheme.fullBillingAddress.city', address.city || '', {
      shouldValidate: true,
    });
    setValue(
      'paymentMethod.scheme.fullBillingAddress.houseNumberOrName',
      address.houseNumberOrName || '',
      {
        shouldValidate: true,
      }
    );
    setValue('paymentMethod.scheme.fullBillingAddress.street', address.street || '', {
      shouldValidate: true,
    });
    setValue(
      'paymentMethod.scheme.fullBillingAddress.stateOrProvince',
      address.stateOrProvince || '',
      {
        shouldValidate: true,
      }
    );
    setValue('paymentMethod.scheme.fullBillingAddress.postalCode', address.postalCode || '', {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (checkout.billingAddressMode === 'FULL') {
      initGoogleMapsApi();
    }
  }, [checkout.billingAddressMode]);

  if (checkout.billingAddressMode === 'NONE') {
    return null;
  }

  if (checkout.billingAddressMode === 'PARTIAL') {
    return (
      <Label className="mt-2" text={t`Postal Code`}>
        <div className="flex flex-col gap-2 col-span-full">
          <InputText
            {...register('paymentMethod.scheme.partialBillingAddress.postalCode', {required: true})}
            placeholder=""
            containerClassName="h-10"
            inputClassName="w-40 sm:text-base"
          />
          <ValidationError
            isVisible={Boolean(errors.paymentMethod?.scheme?.partialBillingAddress?.postalCode)}
          >
            {errors.paymentMethod?.scheme?.partialBillingAddress?.postalCode?.message}
          </ValidationError>
        </div>
      </Label>
    );
  }

  return (
    <Label
      className="mt-4 gap-y-4"
      as="div"
      text={
        <div className="flex items-center justify-between">
          <div>
            <Trans>Billing Address</Trans>
          </div>
          {!isBillingAddressSectionShown && (
            <button
              type="button"
              className="text-sm cursor-pointer w-fit text-primary-500 hover:text-primary-400"
              onClick={showAddressSection}
            >
              <Trans>+ Enter Address Manually</Trans>
            </button>
          )}
        </div>
      }
    >
      <div className="flex flex-col gap-2 col-span-full">
        <Controller
          name="paymentMethod.scheme.fullBillingAddress.searchAddress"
          control={control}
          render={({field}) => (
            <Typeahead
              {...field}
              onSelect={(value: string) => {
                onSelectAddress(value, field.onChange);
              }}
              options={predictions.map(item => ({
                id: item.id,
                label: item.name,
              }))}
              placeholder={t`Search`}
              onChange={handleAddressLineChange}
              wrapperClassName="h-10"
              inputClassName="sm:text-base"
              showSearchIcon
            />
          )}
        />
        {!isBillingAddressSectionShown && (
          <ValidationError
            isVisible={Boolean(errors.paymentMethod?.scheme?.fullBillingAddress?.street)}
          >
            {errors.paymentMethod?.scheme?.fullBillingAddress?.street?.message}
          </ValidationError>
        )}
      </div>
      {isBillingAddressSectionShown && (
        <>
          <div className="flex flex-col gap-2 sm:col-span-3">
            <Controller
              name="paymentMethod.scheme.fullBillingAddress.country"
              control={control}
              rules={{
                required: true,
              }}
              render={({field}) => (
                <InputSelect
                  {...field}
                  options={
                    [
                      {
                        id: 'US',
                        label: t`United States`,
                      },
                      {
                        id: 'CA',
                        label: t`Canada`,
                      },
                      {
                        id: 'AU',
                        label: t`Australia`,
                      },
                      {
                        id: 'GB',
                        label: t`United Kingdom`,
                      },
                      {
                        id: 'NZ',
                        label: t`New Zealand`,
                      },
                    ] satisfies Array<{
                      id: (typeof checkoutSupportedCountries)[number];
                      label: string;
                    }>
                  }
                  placeholder={t`Country`}
                  buttonClassName="h-10 sm:text-base"
                />
              )}
            />
            <ValidationError
              isVisible={Boolean(errors.paymentMethod?.scheme?.fullBillingAddress?.country)}
            >
              {errors.paymentMethod?.scheme?.fullBillingAddress?.country?.message}
            </ValidationError>
          </div>
          <div className="flex flex-col gap-2 col-span-full">
            <InputText
              {...register('paymentMethod.scheme.fullBillingAddress.street', {required: true})}
              placeholder={t`Address`}
              containerClassName="h-10"
              inputClassName="w-40 sm:text-base"
            />
            <ValidationError
              isVisible={Boolean(errors.paymentMethod?.scheme?.fullBillingAddress?.street)}
            >
              {errors.paymentMethod?.scheme?.fullBillingAddress?.street?.message}
            </ValidationError>
          </div>
          <div className="flex flex-col gap-2 col-span-full">
            <InputText
              {...register('paymentMethod.scheme.fullBillingAddress.houseNumberOrName')}
              placeholder={t`Apartment / Suite (optional)`}
              containerClassName="h-10"
              inputClassName="w-40 sm:text-base"
            />
            <ValidationError
              isVisible={Boolean(
                errors.paymentMethod?.scheme?.fullBillingAddress?.houseNumberOrName
              )}
            >
              {errors.paymentMethod?.scheme?.fullBillingAddress?.houseNumberOrName?.message}
            </ValidationError>
          </div>
          <div className="flex flex-col gap-2 col-span-full">
            <InputText
              {...register('paymentMethod.scheme.fullBillingAddress.city', {required: true})}
              placeholder={t`City`}
              containerClassName="h-10"
              inputClassName="w-40 sm:text-base"
            />
            <ValidationError
              isVisible={Boolean(errors.paymentMethod?.scheme?.fullBillingAddress?.city)}
            >
              {errors.paymentMethod?.scheme?.fullBillingAddress?.city?.message}
            </ValidationError>
          </div>
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-3">
              <div className="flex flex-col w-full gap-2">
                <InputText
                  {...register('paymentMethod.scheme.fullBillingAddress.stateOrProvince', {
                    required: true,
                  })}
                  placeholder={t`State or Province`}
                  containerClassName="h-10"
                  inputClassName="w-40 sm:text-base"
                />
                <ValidationError
                  isVisible={Boolean(
                    errors.paymentMethod?.scheme?.fullBillingAddress?.stateOrProvince
                  )}
                >
                  {errors.paymentMethod?.scheme?.fullBillingAddress?.stateOrProvince?.message}
                </ValidationError>
              </div>
            </div>
            <div className="col-span-3">
              <div className="flex flex-col gap-2 md:w-full">
                <InputText
                  {...register('paymentMethod.scheme.fullBillingAddress.postalCode', {
                    required: true,
                  })}
                  placeholder={t`Postal Code`}
                  containerClassName="h-10"
                  inputClassName="w-40 sm:text-base"
                />
                <ValidationError
                  isVisible={Boolean(errors.paymentMethod?.scheme?.fullBillingAddress?.postalCode)}
                >
                  {errors.paymentMethod?.scheme?.fullBillingAddress?.postalCode?.message}
                </ValidationError>
              </div>
            </div>
          </div>
        </>
      )}
    </Label>
  );
};
