import {
  AccountActivityFilters,
  DisplayCategoryMap,
  MerchantAccountPublicStatus,
} from '@zentact/common';
import {MerchantAccountStatus} from '@zentact/db';
import {DateTime} from 'luxon';
import {useCallback} from 'react';
import {MerchantAccountsPickerFilter} from '../dropdowns';
import {ResetTableFiltersButton} from '../table';
import {CheckboxFilter} from './CheckboxFilter';
import {DashboardDatepicker} from './DashboardDatepicker';
import {EntityPickerFilter} from './EntityPickerFilter';

type Props<T extends AccountActivityFilters> = {
  defaultFilters: T;
  typedSearchParams: T;
  setTypedSearchParams: (params: T) => void;
  selectedOrganization?: string | null;
  selectedMerchantAccount?: string | null;
  organizationList?: {id: string; name: string}[];
  merchantAccounts?: {
    id: string;
    businessName: string;
    organizationId: string | null;
    status: MerchantAccountStatus | MerchantAccountPublicStatus;
    onboardedAt: string | null;
  }[];
  category?: string[];
  categoryList: string[];
  dateValue: {
    startDate: Date;
    endDate?: Date | null;
  } | null;
  handleDateChange: (dateRange: {
    startDate: Date;
    endDate: Date;
  }) => void;
};

export const ActivityDetailsFilters = <T extends AccountActivityFilters>({
  defaultFilters,
  typedSearchParams,
  setTypedSearchParams,
  selectedOrganization,
  selectedMerchantAccount,
  category,
  categoryList,
  merchantAccounts,
  dateValue,
  organizationList,
  handleDateChange,
}: Props<T>) => {
  const handleFilterChange = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setTypedSearchParams({[key]: value} as unknown as T);
    },
    [setTypedSearchParams]
  );

  const handleMerchantChange = useCallback(
    (value: string | undefined) => {
      handleFilterChange('merchantAccountId', value);
    },
    [handleFilterChange]
  );

  return (
    <div className="flex flex-wrap items-center justify-end gap-2 lg:flex-nowrap">
      <div className="block lg:hidden 2xl:block max-lg:w-full">
        <ResetTableFiltersButton
          defaultFilters={defaultFilters}
          activeFilters={typedSearchParams}
          setFilters={setTypedSearchParams}
          ignoreKeys={['endDate']}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        {organizationList && (
          <EntityPickerFilter
            selected={selectedOrganization || ''}
            onChange={value => handleFilterChange('organizationId', value)}
            options={organizationList}
          />
        )}
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        {!!merchantAccounts?.length && (
          <div className="font-normal shrink-0 max-lg:w-full">
            <MerchantAccountsPickerFilter
              selectedMerchantAccount={selectedMerchantAccount || ''}
              onSelectMerchantAccount={handleMerchantChange}
              merchantAccountsOptions={
                selectedOrganization
                  ? merchantAccounts.filter(
                      merchantAccount => merchantAccount.organizationId === selectedOrganization
                    )
                  : merchantAccounts
              }
              allLabel={'Merchant'}
            />
          </div>
        )}
      </div>
      <div className="max-lg:w-full">
        <CheckboxFilter
          label="Category"
          selected={category}
          onChange={value => handleFilterChange('category', value)}
          options={Object.entries(DisplayCategoryMap)
            .filter(([key]) => categoryList.includes(key))
            .map(([key, value]) => ({
              element: <div className="flex items-center gap-1 whitespace-nowrap">{value}</div>,
              key,
            }))}
          notFoundText="No Categories Found"
        />
      </div>
      <div className="w-full mt-4 shrink-0 md:mt-0 lg:w-64">
        <DashboardDatepicker
          onChange={handleDateChange}
          dateRange={
            dateValue
              ? {
                  startDate: DateTime.fromJSDate(dateValue.startDate).startOf('day').toJSDate(),
                  endDate: dateValue.endDate
                    ? DateTime.fromJSDate(dateValue.endDate).endOf('day').toJSDate()
                    : DateTime.fromJSDate(dateValue.startDate).endOf('day').toJSDate(),
                }
              : null
          }
        />
      </div>
    </div>
  );
};
