import {MerchantAccountPublicStatus} from '@zentact/common';
import {FlatPillWithDot} from '@zentact/ui-tailwind';

type PillProps = React.ComponentProps<typeof FlatPillWithDot>;

export const merchantAccountStatusToColor: Record<MerchantAccountPublicStatus, PillProps['color']> =
  {
    [MerchantAccountPublicStatus.ACTIVE]: 'green',
    [MerchantAccountPublicStatus.INITIATED]: 'yellow',
    [MerchantAccountPublicStatus.INVITED]: 'yellow',
    [MerchantAccountPublicStatus.ONBOARDING]: 'yellow',
    [MerchantAccountPublicStatus.AWAITING_INFO]: 'yellow',
    [MerchantAccountPublicStatus.AWAITING_REVIEW]: 'yellow',
    [MerchantAccountPublicStatus.INVITE_EXPIRED]: 'red',
    [MerchantAccountPublicStatus.INVITE_REVOKED]: 'gray',
    [MerchantAccountPublicStatus.DEACTIVATED]: 'gray',
    [MerchantAccountPublicStatus.ONBOARDING_CANCELLED]: 'red',
    [MerchantAccountPublicStatus.REJECTED]: 'red',
    [MerchantAccountPublicStatus.REVIEW_REJECTED]: 'red',
    [MerchantAccountPublicStatus.NEEDS_ORGANIZATION]: 'red',
    [MerchantAccountPublicStatus.ASSIGNING_ORGANIZATION]: 'gray',
  };
