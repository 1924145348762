import {LanguageCode, LocaleCode} from '../constants/enums';

const errorMessageMap: {[key: string]: {[K in LanguageCode | LocaleCode]?: string}} = {
  '1_10': {
    [LanguageCode.en]: "Information couldn't be verified",
    [LanguageCode.ps]: "Information couldn't be verified",
  },
  '1_11': {
    [LanguageCode.en]: "Document didn't meet requirements",
    [LanguageCode.ps]: "Document didn't meet requirements",
  },
  '1_12': {
    [LanguageCode.en]: 'Legal entity declined',
    [LanguageCode.ps]: 'Legal entity declined',
  },
  '1_13': {
    [LanguageCode.en]: "Verification couldn't be completed",
    [LanguageCode.ps]: "Verification couldn't be completed",
  },
  '1_14': {
    [LanguageCode.en]: 'Capability not allowed for type individual',
    [LanguageCode.ps]: 'Capability not allowed for type individual',
  },
  '1_30': {
    [LanguageCode.en]: "Individual details couldn't be verified",
    [LanguageCode.ps]: "Individual details couldn't be verified",
  },
  '1_31': {
    [LanguageCode.en]: 'ID document is needed',
    [LanguageCode.ps]: 'ID document is needed',
  },
  '1_32': {
    [LanguageCode.en]: "ID document couldn't be processed",
    [LanguageCode.ps]: "ID document couldn't be processed",
  },
  '1_33': {
    [LanguageCode.en]: "ID document didn't meet requirements",
    [LanguageCode.ps]: "ID document didn't meet requirements",
  },
  '1_34': {
    [LanguageCode.en]: "Image of the ID document didn't meet requirements",
    [LanguageCode.ps]: "Image of the ID document didn't meet requirements",
  },
  '1_35': {
    [LanguageCode.en]: "Individual details didn't match the ID document",
    [LanguageCode.ps]: "Individual details didn't match the ID document",
  },
  '1_36': {
    [LanguageCode.en]: "Proof of residency didn't meet requirements",
    [LanguageCode.ps]: "Proof of residency didn't meet requirements",
  },
  '1_37': {
    [LanguageCode.en]: "Image of the proof of residency didn't meet the requirements",
    [LanguageCode.ps]: "Image of the proof of residency didn't meet the requirements",
  },
  '1_38': {
    [LanguageCode.en]: "Individual details didn't match the proof of residency",
    [LanguageCode.ps]: "Individual details didn't match the proof of residency",
  },
  '1_39': {
    [LanguageCode.en]: "Proof of national ID number didn't meet requirements",
    [LanguageCode.ps]: "Proof of national ID number didn't meet requirements",
  },
  '1_40': {
    [LanguageCode.en]: "Image of the proof of national ID number didn't meet the requirements",
    [LanguageCode.ps]: "Image of the proof of national ID number didn't meet the requirements",
  },
  '1_41': {
    [LanguageCode.en]: "Individual details didn't match the proof of national ID number",
    [LanguageCode.ps]: "Individual details didn't match the proof of national ID number",
  },
  '1_42': {
    [LanguageCode.en]: "Individual details didn't meet the format requirements",
    [LanguageCode.ps]: "Individual details didn't meet the format requirements",
  },
  '1_43': {
    [LanguageCode.en]: "Image of the proof of the individual tax ID didn't meet the requirements",
    [LanguageCode.ps]: "Image of the proof of the individual tax ID didn't meet the requirements",
  },
  '1_44': {
    [LanguageCode.en]: "Proof of the individual tax ID didn't meet requirements",
    [LanguageCode.ps]: "Proof of the individual tax ID didn't meet requirements",
  },
  '1_45': {
    [LanguageCode.en]: "Individual details didn't match the proof of the individual tax ID",
    [LanguageCode.ps]: "Individual details didn't match the proof of the individual tax ID",
  },
  '1_50': {
    [LanguageCode.en]: "Organization details couldn't be verified",
    [LanguageCode.ps]: "Organization details couldn't be verified",
  },
  '1_51': {
    [LanguageCode.en]: "Organization details didn't match the document",
    [LanguageCode.ps]: "Organization details didn't match the document",
  },
  '1_52': {
    [LanguageCode.en]: "Registration document didn't meet requirements",
    [LanguageCode.ps]: "Registration document didn't meet requirements",
  },
  '1_53': {
    [LanguageCode.en]: "Tax document didn't meet requirements",
    [LanguageCode.ps]: "Tax document didn't meet requirements",
  },
  '1_54': {
    [LanguageCode.en]: "Proof of address didn't meet requirements",
    [LanguageCode.ps]: "Proof of address didn't meet requirements",
  },
  '1_55': {
    [LanguageCode.en]: 'Proof of ownership is needed',
    [LanguageCode.ps]: 'Proof of ownership is needed',
  },
  '1_56': {
    [LanguageCode.en]: "Organization ownership couldn't be verified",
    [LanguageCode.ps]: "Organization ownership couldn't be verified",
  },
  '1_57': {
    [LanguageCode.en]: "Submitted affiliation document didn't meet the requirements",
    [LanguageCode.ps]: "Submitted affiliation document didn't meet the requirements",
  },
  '1_58': {
    [LanguageCode.en]: "VAT document didn't meet requirements",
    [LanguageCode.ps]: "VAT document didn't meet requirements",
  },
  '1_59': {
    [LanguageCode.en]: "Organization details didn't meet the format requirements",
    [LanguageCode.ps]: "Organization details didn't meet the format requirements",
  },
  '1_60': {
    [LanguageCode.en]: "Legal arrangement details couldn't be verified",
    [LanguageCode.ps]: "Legal arrangement details couldn't be verified",
  },
  '1_61': {
    [LanguageCode.en]: "Constitutional document didn't meet requirements",
    [LanguageCode.ps]: "Constitutional document didn't meet requirements",
  },
  '1_62': {
    [LanguageCode.en]: "Legal arrangement details didn't match the constitutional document",
    [LanguageCode.ps]: "Legal arrangement details didn't match the constitutional document",
  },
  '1_63': {
    [LanguageCode.en]:
      "Legal arrangement's affiliated member's details didn't match the constitutional document",
    [LanguageCode.ps]:
      "Legal arrangement's affiliated member's details didn't match the constitutional document",
  },
  '1_70': {
    [LanguageCode.en]: "Bank account couldn't be verified",
    [LanguageCode.ps]: "Bank account couldn't be verified",
  },
  '1_71': {
    [LanguageCode.en]: "Bank account details couldn't be verified",
    [LanguageCode.ps]: "Bank account details couldn't be verified",
  },
  '1_72': {
    [LanguageCode.en]: 'Verification could not be completed',
    [LanguageCode.ps]: 'Verification could not be completed',
  },
  '1_73': {
    [LanguageCode.en]: 'Bank account access could not be authenticated',
    [LanguageCode.ps]: 'Bank account access could not be authenticated',
  },
  '1_74': {
    [LanguageCode.en]: 'Bank statement is needed',
    [LanguageCode.ps]: 'Bank statement is needed',
  },
  '1_75': {
    [LanguageCode.en]: "Bank statement didn't meet requirements",
    [LanguageCode.ps]: "Bank statement didn't meet requirements",
  },
  '1_76': {
    [LanguageCode.en]: "Bank details didn't match the bank document",
    [LanguageCode.ps]: "Bank details didn't match the bank document",
  },
  '1_77': {
    [LanguageCode.en]: 'The entity is in pending status',
    [LanguageCode.ps]: 'The entity is in pending status',
  },
  '1_81': {
    [LanguageCode.en]: "Signatory details didn't match proof of signatory",
    [LanguageCode.ps]: "Signatory details didn't match proof of signatory",
  },
  '1_82': {
    [LanguageCode.en]: "Proof of signatory didn't meet requirements",
    [LanguageCode.ps]: "Proof of signatory didn't meet requirements",
  },
  '1_83': {
    [LanguageCode.en]: "Signatory details couldn't be verified",
    [LanguageCode.ps]: "Signatory details couldn't be verified",
  },
  '1_90': {
    [LanguageCode.en]: "Industry couldn't be verified",
    [LanguageCode.ps]: "Industry couldn't be verified",
  },
  '1_91': {
    [LanguageCode.en]: "Proof of industry didn't meet requirements",
    [LanguageCode.ps]: "Proof of industry didn't meet requirements",
  },
  '1_92': {
    [LanguageCode.en]: 'No web address available to perform verification',
    [LanguageCode.ps]: 'No web address available to perform verification',
  },
  '2_8': {
    [LanguageCode.en]: 'Some information was missing',
    [LanguageCode.ps]: 'Some information was missing',
  },
  '2_901': {
    [LanguageCode.en]: 'PCI forms are not signed',
    [LanguageCode.ps]: 'PCI forms are not signed',
  },
  '2_902': {
    [LanguageCode.en]: 'Terms Of Service forms are not accepted',
    [LanguageCode.ps]: 'Terms Of Service forms are not accepted',
  },
  '2_904': {
    [LanguageCode.en]: 'Integration is not fully implemented',
    [LanguageCode.ps]: 'Integration is not fully implemented',
  },
  '3_10': {
    [LanguageCode.en]: 'Review of data is required',
    [LanguageCode.ps]: 'Review of data is required',
  },
  default: {
    [LanguageCode.en]: 'Unknown error',
    [LanguageCode.ps]: 'Unknown error',
    [LocaleCode.en_US]: 'Unknown error',
    [LocaleCode.en_GB]: 'Unknown error',
    [LocaleCode.ps_DO]: 'Unknown error',
  },
};

export const getErrorMessage = (errorCode: string, locale: LocaleCode = LocaleCode.en_US) => {
  const localeErrorMessage = errorMessageMap[errorCode]?.[locale];

  const languageCode = locale.split('-')[0] as LanguageCode;
  const languageErrorMessage = errorMessageMap[errorCode]?.[languageCode];

  return (
    localeErrorMessage ??
    languageErrorMessage ??
    errorMessageMap.default?.[languageCode] ??
    errorMessageMap.default?.[locale]
  );
};
