import {Trans} from '@lingui/macro';
import {GetCheckoutV2Output} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {
  AchIcon,
  ApplePayIcon,
  InputRadio,
  Typography,
  VisaMastercardIcon,
} from '@zentact/ui-tailwind';
import {UseFormReturn} from 'react-hook-form';
import {CheckoutV2FormData} from './checkout-schema';

type PaymentMethodSelectorProps = {
  form: UseFormReturn<CheckoutV2FormData>;
  checkout: GetCheckoutV2Output;
};

export const PaymentMethodSelector = ({form, checkout}: PaymentMethodSelectorProps) => {
  const {register, watch} = form;
  const {availablePaymentMethods} = checkout;
  const type = watch('paymentMethod.type');

  return (
    <div className="flex flex-col gap-2 mt-4 sm:col-span-4">
      <Typography variant="header-form-section">
        <Trans>Payment Method</Trans>
      </Typography>
      <div className="flex gap-2">
        {!!availablePaymentMethods.savedMethod && (
          <InputRadio
            {...register('paymentMethod.type')}
            value="savedMethod"
            inputClassName="invisible absolute -left-full"
            labelClassName="ms-0"
            label={
              <div
                className={`border w-20 h-16 text-primary-600 text-center flex items-center justify-center ${
                  type === 'savedMethod' ? 'border-primary-600' : 'border-gray-300'
                } rounded-lg`}
              >
                <Trans>Saved Methods</Trans>
              </div>
            }
          />
        )}
        {!!availablePaymentMethods.scheme && (
          <InputRadio
            {...register('paymentMethod.type')}
            value="scheme"
            inputClassName="invisible absolute -left-full"
            labelClassName="ms-0"
            label={
              <div
                className={`border px-2 ${
                  type === 'scheme' ? 'border-primary-600' : 'border-gray-300'
                } rounded-lg`}
              >
                <VisaMastercardIcon />
              </div>
            }
          />
        )}
        {!!availablePaymentMethods.ach && (
          <InputRadio
            {...register('paymentMethod.type')}
            inputClassName="invisible absolute -left-full"
            labelClassName="ms-0"
            value="ach"
            label={
              <div
                className={`border px-2 ${
                  type === 'ach' ? 'border-primary-600' : 'border-gray-300'
                } rounded-lg`}
              >
                <AchIcon />
              </div>
            }
          />
        )}
        {!!availablePaymentMethods.applepay && (
          <div className="flex flex-row items-center gap-x-3 gap-y-4">
            <InputRadio
              {...register('paymentMethod.type')}
              inputClassName="invisible absolute -left-full"
              labelClassName="ms-0"
              value="applepay"
              label={
                <div
                  className={`border px-2 ${
                    type === 'applepay' ? 'border-blue-500' : 'border-gray-300'
                  } rounded-lg`}
                >
                  <ApplePayIcon />
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
