import {LanguageCode, LocaleCode} from '../constants/enums';

const paymentSourceNameMap: {[key: string]: {[K in LanguageCode | LocaleCode]?: string}} = {
  // biome-ignore lint/style/useNamingConvention: <explanation>
  TERMINAL: {
    [LanguageCode.en]: 'Terminal',
    [LanguageCode.ps]: 'Terminal',
  },
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ECOMMERCE: {
    [LanguageCode.en]: 'Ecommerce',
    [LanguageCode.ps]: 'Ecommerce',
  },
  // biome-ignore lint/style/useNamingConvention: <explanation>
  SAVED_PAYMENT_METHOD: {
    [LanguageCode.en]: 'Saved Payment Method',
    [LanguageCode.ps]: 'Saved Payment Method',
  },
};

export const getPaymentSourceName = (
  paymentSource: string,
  locale: LocaleCode = LocaleCode.en_US
) => {
  const localeName = paymentSourceNameMap[paymentSource]?.[locale];

  const languageCode = locale.split('-')[0] as LanguageCode;
  const languageName = paymentSourceNameMap[paymentSource]?.[languageCode];

  return localeName ?? languageName ?? paymentSource;
};
