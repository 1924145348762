import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/24/outline';
import {Header, Table, flexRender} from '@tanstack/react-table';
import {FilterOnChange, TableSort, TableSortValue} from '.';
import {QuestionTooltip} from '..';
import {cn} from '../utils';

const nextSort: Record<string, TableSort | null> = {
  asc: null,
  desc: 'asc',
  null: 'desc',
};

export const TableHeaderCellWrapper = <
  TItem,
  TFilters extends Record<string, unknown> = Record<string, unknown>,
>({
  header,
  filterValues,
  table,
  onSortChange,
  onFilterChange,
  sortValue,
}: {
  header: Header<TItem, unknown>;
  filterValues?: TFilters;
  table: Table<TItem>;
  sortValue?: TableSortValue<string> | null;
  onFilterChange: FilterOnChange;
  onSortChange?: (args: TableSortValue<string>) => void;
}) => {
  const {
    filter,
    sort,
    align = 'left',
    headerTooltip: tooltipText,
  } = header.column.columnDef.meta ?? {};
  const hover =
    header.id === 'actions' || (!sort && !filter) ? '' : 'hover:bg-gray-100 cursor-pointer';
  const currentSort = sortValue && sortValue.columnId === header.id ? sortValue.value : null;

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: TODO
    <th
      key={header.id}
      className={cn(
        `${align === 'left' ? 'pl-4' : 'pr-4'} text-${align} text-sm ${hover}`,
        'font-semibold text-gray-900',
        currentSort && 'bg-gray-200'
      )}
      colSpan={header.colSpan}
      onClick={() => {
        if (sort) {
          onSortChange?.({
            columnId: header.id,
            value: nextSort[String(currentSort)] as TableSort | null,
          });
        }
      }}
    >
      <div
        className={cn(
          'relative flex gap-2 items-center flex-nowrap',
          align === 'left' ? 'justify-start' : 'justify-end',
          header.column.columnDef.meta?.headerCellClassName
        )}
      >
        <div
          className={cn(
            'whitespace-nowrap py-3.5',
            header.column.columnDef.meta?.columnCellClassName
          )}
        >
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </div>
        {!!tooltipText && <QuestionTooltip text={tooltipText} />}
        {((sort && currentSort) || filter) && (
          <div className="flex items-center flex-nowrap">
            {sort && currentSort && (
              <div className="p-0 rounded-sm focus-visible:outline-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                {currentSort === 'desc' && <ChevronDownIcon className="w-4 h-4 text-primary-600" />}
                {currentSort === 'asc' && <ChevronUpIcon className="w-4 h-4 text-primary-600" />}
              </div>
            )}
            {filter?.renderFilter({
              column: header.column,
              table,
              filterValues,
              filterId: filter.filterId,
              onChange: onFilterChange,
            })}
          </div>
        )}
      </div>
    </th>
  );
};
