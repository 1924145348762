import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {CheckCircleIcon} from '@heroicons/react/20/solid';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  ErrorCode,
  INVOICE_ID_ATTRIBUTE,
  calculateAmountWithSurcharge,
  formatAmount,
  formatPhone,
  isFormattedTrpcError,
} from '@zentact/common';
import {SubmitPaymentResponse} from '@zentact/core';
import {
  Button,
  InputText,
  Label,
  Loading,
  Typography,
  ValidationError,
  applyTheme,
  cn,
  useNotification,
} from '@zentact/ui-tailwind';
import {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {CheckoutOrderDetailsSection} from '../checkout-order-details-section';
import {useAdyenAch} from './adyen-components/use-adyen-ach';
import {useAdyenCustomCard} from './adyen-components/use-adyen-custom-card';
import {CheckoutV2FormData, checkoutV2FormSchema} from './checkout-schema';
import {AchPaymentMethodForm} from './payment-method-forms/ach-payment-method-form';
import {SchemePaymentMethodForm} from './payment-method-forms/scheme-payment-method-form';
import {PaymentMethodSelector} from './payment-method-selector';

export const CheckoutV2Page = () => {
  const {tenant} = useStore();
  const navigate = useNavigate();
  const {initiateAdyenCustomCardComponent, customCardComponent} = useAdyenCustomCard();
  const {achComponent, initiateAdyenAchComponent} = useAdyenAch();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const defaultPaymentMethod = 'scheme';
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(defaultPaymentMethod);
  const {showErrorNotification} = useNotification();

  const {data: checkout} = trpc.checkout.getCheckoutInfoV2.useQuery(undefined, {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    onError: error => {
      if (isSubmitted) {
        // ignore if the payment was successful
        return;
      }
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      navigate(RoutePath.ERROR, {state: isFormattedTrpcError(error) ? error.data : {errorCode}});
    },
    enabled: !isSubmitted,
  });

  useEffect(() => {
    if (!checkout) {
      return;
    }
    applyTheme({
      primary:
        checkout.savePaymentMethod === 'ADD_EMBED' && tenant
          ? tenant.brandConfiguration.primaryColorHex
          : checkout.brandingConfiguration.primaryColorHex,
    });
  }, [checkout, tenant]);

  useEffect(() => {
    const paymentMethods = checkout?.availablePaymentMethods;

    // if loading or scheme is available(selected by default) or different method is already selected
    if (
      !paymentMethods ||
      !!paymentMethods[defaultPaymentMethod] ||
      selectedPaymentMethod !== defaultPaymentMethod
    ) {
      return;
    }

    // In case if default payment method is not available, select the first available payment method
    const firstAvailablePaymentMethod = Object.values(paymentMethods).find(
      p => !!p && 'type' in p
    )?.type;
    if (firstAvailablePaymentMethod) {
      setSelectedPaymentMethod(firstAvailablePaymentMethod);
    }
  }, [checkout, selectedPaymentMethod]);

  const showTemporaryErrorMessage = (message: string, title: string) => {
    showErrorNotification(title, message);
    // for future dropin component
    // component.setStatus('error');
    // setTimeout(() => {
    //   component.setStatus('ready');
    // }, 3500);
  };

  const submitPayment = trpc.checkout.submitPayment.useMutation();

  const onSubmit = async (data: CheckoutV2FormData) => {
    if (!checkout) {
      return;
    }
    if (data.paymentMethod.type === 'scheme') {
      if (!customCardComponent) {
        return;
      }
      if (!customCardComponent.isValid) return customCardComponent.showValidation();
    }
    if (data.paymentMethod.type === 'ach') {
      if (!achComponent) {
        return;
      }
      if (!achComponent.isValid) return achComponent.showValidation();
    }
    setSubmitting(true);

    const prepareSubmitPaymentRequestData = () => {
      switch (data.paymentMethod.type) {
        case 'ach':
          if (!achComponent) {
            throw Error('invalid achComponent');
          }
          return {
            paymentMethod: {
              ...achComponent.state.data,
              ownerName: achComponent.state.data.holderName,
              type: data.paymentMethod.type,
            },
          };
        case 'scheme':
          if (!customCardComponent) {
            throw Error('invalid customCardComponent');
          }
          const {holderName, fullBillingAddress, partialBillingAddress} = data.paymentMethod.scheme;

          const paymentMethod = {
            type: data.paymentMethod.type,
            holderName,
            ...customCardComponent.state.data,
          };

          const billingAddress =
            checkout.billingAddressMode === 'FULL'
              ? fullBillingAddress
              : checkout.billingAddressMode === 'PARTIAL' && partialBillingAddress
                ? {
                    stateOrProvince: 'N/A',
                    ...partialBillingAddress,
                  }
                : undefined;

          return {
            paymentMethod,
            billingAddress,
          };
        default:
          throw new Error(`Not supported payment method type ${type}`);
      }
    };
    const {paymentMethod, billingAddress} = prepareSubmitPaymentRequestData();

    let response: SubmitPaymentResponse;
    try {
      response = await submitPayment.mutateAsync({
        // adyenSessionId: checkout.id, todo add for dropin only
        paymentMethod,
        ...(billingAddress && {billingAddress}),
        // storePaymentMethod: storePaymentMethodEnabled,
      });
    } catch (err) {
      if (err instanceof Error) {
        showTemporaryErrorMessage(t`${err.message}`, t`Payment Error`);
      } else {
        showTemporaryErrorMessage(t`An unknown error has occurred`, t`Payment Error`);
      }
      setSubmitting(false);
      return;
    }
    setSubmitting(false);

    const isSuccess = response.resultCode === 'Authorised' || response.resultCode === 'Received';

    if (!response.resultCode) {
      // todo
      return;
    }
    if (isSuccess) {
      setIsSubmitted(true);
      if (response.returnUrl) {
        setTimeout(() => {
          // biome-ignore lint/style/noNonNullAssertion: checked in the enclosing if statement
          window.location.href = response.returnUrl!;
        }, 2500);
      }

      return;
    }
    if (response.resultCode === 'Refused') {
      showTemporaryErrorMessage(
        t`Please validate your payment information or try a different payment method.`,
        t`Payment Refused`
      );
      return;
    }
    if (response.resultCode === 'Error') {
      showTemporaryErrorMessage(
        t`Please try again. You might need to contact your bank or try a different payment method.`,
        t`Payment Error`
      );
      return;
    }
    if (response.resultCode === 'Cancelled') {
      showTemporaryErrorMessage(
        t`Please verify your payment information and address details, or try an alternative payment method.`,
        t`Payment Declined`
      );
      return;
    }
  };

  const form = useForm<CheckoutV2FormData>({
    resolver: zodResolver(checkoutV2FormSchema()),
    defaultValues: {
      paymentMethod: {type: 'scheme', ach: {}, scheme: {}},
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = form;

  const type = watch('paymentMethod.type');
  const email = watch('email');

  const invoiceNumber = checkout?.checkoutAttributes.find(
    attribute => attribute.name === INVOICE_ID_ATTRIBUTE
  )?.value;

  const phoneNumber = checkout?.brandingConfiguration.phoneNumber;
  const supportEmail = checkout?.brandingConfiguration.supportEmail;

  const supportSection = useMemo(() => {
    if (!phoneNumber && !supportEmail) {
      return null;
    }
    return (
      <p className="mx-4 md:mx-auto md:w-full md:max-w-[480px] text-sm text-center md:text-white text-gray-500 md:mt-10">
        <Trans>For support, please contact</Trans>{' '}
        {phoneNumber && (
          <>
            <a
              href={`tel:${phoneNumber}`}
              className="font-semibold leading-6 whitespace-nowrap text-primary-600 hover:text-primary-500 md:hover:text-primary-100 md:text-primary-200"
              aria-label={`Call ${formatPhone(phoneNumber)}`}
            >
              {formatPhone(phoneNumber)}
            </a>{' '}
            {phoneNumber && supportEmail && <Trans>or</Trans>}{' '}
          </>
        )}
        {supportEmail && (
          <a
            href={`mailto:${supportEmail}`}
            className="font-semibold leading-6 whitespace-nowrap text-primary-600 hover:text-primary-500 md:hover:text-primary-100 md:text-primary-200"
            aria-label={`Email ${supportEmail}`}
          >
            {supportEmail}
          </a>
        )}
        .
      </p>
    );
  }, [supportEmail, phoneNumber]);

  const {authorizedAmount, surchargeAmount, surchargeConfiguration} = useMemo(() => {
    const surchargeConfiguration =
      checkout?.surchargeConfiguration &&
      Object.keys(checkout.surchargeConfiguration).includes(type)
        ? checkout.surchargeConfiguration[type as keyof typeof checkout.surchargeConfiguration]
        : null;
    if (checkout && checkout.amount > 0 && surchargeConfiguration) {
      return calculateAmountWithSurcharge(checkout.amount, surchargeConfiguration);
    }
    return {authorizedAmount: checkout?.amount, surchargeAmount: 0, surchargeConfiguration};
  }, [type, checkout]);

  const detailsText = useMemo(() => {
    switch (type) {
      case 'ach':
        return <Trans>ACH Details</Trans>;
      case 'scheme':
        return <Trans>Credit Card Details</Trans>;
      default:
        return <Trans>Details</Trans>;
    }
  }, [type]);

  const isOrderDetailsShown = !!checkout?.orderDetails || !!invoiceNumber;
  const surchargePaymentLabel = useMemo(() => {
    if (isOrderDetailsShown || !surchargeConfiguration || !surchargeAmount || !checkout) {
      return null;
    }
    const getSurchargeString = () => {
      if (surchargeConfiguration.percentageFee > 0 && surchargeConfiguration.fixedFee > 0) {
        return `(${surchargeConfiguration.percentageFee}% + ${formatAmount(
          surchargeConfiguration.fixedFee,
          checkout.locale,
          checkout.currency
        )})`;
      }
      if (surchargeConfiguration.percentageFee > 0) {
        return `(${surchargeConfiguration.percentageFee}%)`;
      }
      return null;
    };
    return (
      <div className="mb-4 font-medium">
        <Trans>Payment Surcharge</Trans> {getSurchargeString()} -{' '}
        {formatAmount(surchargeAmount, checkout.locale, checkout.currency)}
      </div>
    );
  }, [surchargeAmount, isOrderDetailsShown, checkout, surchargeConfiguration]);

  if (!checkout) {
    return <Loading />;
  }

  if (isSubmitted) {
    return (
      <div className="flex flex-col items-center justify-center flex-1 min-h-screen pt-0 pb-8 md:px-6 md:pt-12 md:bg-primary-500">
        <div className="md:p-7 p-4 bg-white md:shadow md:mx-auto md:w-full md:max-w-[480px] md:rounded-[20px] text-center">
          <div className="flex justify-center mb-4">
            <CheckCircleIcon height="150" className="text-primary-500" />
          </div>
          <div className="flex justify-center mt-12 text-2xl font-semibold">
            <Trans>Payment Complete!</Trans>
          </div>
          <div className="flex text-sm font-semibold justify-center mt-12 mb-[30px]">
            <Trans>
              You sent {formatAmount(authorizedAmount, checkout.locale, checkout.currency)} to{' '}
              {checkout.brandingConfiguration.displayName}.
              {checkout.shopperEmail || email
                ? ` We sent a receipt to ${checkout.shopperEmail || email} for your records.`
                : ''}
            </Trans>
          </div>
        </div>
        {supportSection}
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex flex-col min-h-screen md:bg-primary-500',
        !checkout?.orderDetails && !invoiceNumber ? 'flex-col' : 'flex-col md:flex-row'
      )}
    >
      {!!checkout && !!authorizedAmount && (
        <CheckoutOrderDetailsSection
          orderDetails={checkout.orderDetails}
          total={authorizedAmount}
          brandConfiguration={checkout.brandingConfiguration}
          invoiceNumber={invoiceNumber}
          currency={checkout.currency}
          showCheckoutPageInFullScreen={!!tenant?.features?.showCheckoutPageInFullScreen}
          surchargeAmount={surchargeAmount}
          surchargeConfiguration={surchargeConfiguration}
        />
      )}
      <div className="flex flex-col justify-center flex-1 min-h-full pt-0 pb-8 md:px-6 md:pt-12">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="md:p-7 p-4 bg-white md:shadow md:mx-auto md:w-full md:max-w-[480px] md:rounded-[20px] "
        >
          <Typography variant="header-page" className="flex">
            <Trans>Payment Details</Trans>
          </Typography>
          <div className="mt-2">
            <div className="text-sm text-gray-500">{t`Complete your purchase by providing payment details`}</div>
          </div>
          <Label
            className="mt-4"
            text={
              <Typography variant="header-form-section">
                <Trans>Email</Trans>
              </Typography>
            }
          >
            <InputText
              {...register('email', {
                required: true,
                value: checkout?.shopperEmail ?? '',
              })}
              containerClassName="h-10"
              inputClassName="sm:text-base"
              placeholder="example@example.com"
            />
            <ValidationError isVisible={Boolean(errors.email?.message)}>
              {errors.email?.message}
            </ValidationError>
          </Label>
          <PaymentMethodSelector form={form} checkout={checkout} />
          <Typography variant="header-form-section" className="mt-8">
            {detailsText}
          </Typography>
          {type === 'scheme' && (
            <SchemePaymentMethodForm
              checkout={checkout}
              form={form}
              initiateAdyenCustomCardComponent={initiateAdyenCustomCardComponent}
            />
          )}
          {type === 'ach' && (
            <AchPaymentMethodForm
              checkout={checkout}
              initiateAdyenAchComponent={initiateAdyenAchComponent}
            />
          )}
          <div className="mt-8">
            {surchargePaymentLabel}
            {checkout && (
              <Button
                type="submit"
                className="h-12 bg-primary-500 hover:bg-primary-600"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                <Trans>Pay</Trans>{' '}
                {formatAmount(authorizedAmount, checkout.locale, checkout.currency)}
              </Button>
            )}
          </div>
        </form>
        {supportSection}
      </div>
    </div>
  );
};
