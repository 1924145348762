import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {PaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatLocaleDate,
} from '@zentact/common';
import {
  FlatPillWithDot,
  TruncatedText,
  displayPaymentStatusMap,
  paymentStatusToColor,
} from '@zentact/ui-tailwind';

const columnsHelper = createColumnHelper<PaymentListItemOutput>();

type Props = {
  locale: LocaleCode;
  i18n: I18n;
};

export const getLatestPaymentColumns = ({locale, i18n}: Props) => {
  return [
    columnsHelper.accessor('createdAt', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => i18n._('Date'),
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      id: 'merchantName',
      cell: props => <TruncatedText text={props.row.original.merchantAccount.businessName} />,
      header: () => i18n._('Merchant Account'),
      meta: {
        collapseAt: 'md',
      },
    }),
    columnsHelper.accessor(
      row => formatAmount(row.authorizedAmount, locale, row.currency as CurrencyCode),
      {
        id: 'authorizedAmount',
        header: () => i18n._('Amount'),
        size: TableColumnSize.XXS,
        meta: {
          align: 'right',
        },
      }
    ),
    columnsHelper.accessor('status', {
      cell: info => (
        <FlatPillWithDot
          color={paymentStatusToColor[info.getValue()] || 'blue'}
          label={displayPaymentStatusMap[info.getValue()] ?? info.getValue()}
        />
      ),
      header: () => i18n._('Status'),
      meta: {
        collapseAt: 'lg',
        align: 'right',
      },
      size: TableColumnSize.XS,
    }),
  ];
};
