import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {PayoutsSummaryItem} from '@zentact/api/src/trpc/routers/financeRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatLocaleDate,
} from '@zentact/common';
import {DropDownMinimalMenuIcon, TruncatedText, getTableMeta} from '@zentact/ui-tailwind';
import {Link} from 'react-router-dom';

const columnsHelper = createColumnHelper<PayoutsSummaryItem>();

type Props = {
  locale: LocaleCode;
  i18n: I18n;
  showOrganizationColumn?: boolean;
  showMerchantColumn?: boolean;
  openDetailsPanel: (row: PayoutsSummaryItem) => void;
  activityDetailsPath: string;
};

export const getPayoutsColumns = ({
  locale,
  i18n,
  showMerchantColumn,
  showOrganizationColumn,
  openDetailsPanel,
  activityDetailsPath,
}: Props) => [
  columnsHelper.accessor('valueDate', {
    cell: props => formatLocaleDate(props.getValue()),
    header: () => i18n._('Transfer Date'),
    meta: {
      sort: {
        isSortable: true,
      },
    },
    size: TableColumnSize.S,
  }),
  ...(showMerchantColumn
    ? [
        columnsHelper.accessor('merchantAccountName', {
          cell: row => <TruncatedText text={row.getValue() as string} />,
          header: () => i18n._('Merchant Account'),
          meta: {
            collapseAt: 'sm',
          },
        }),
      ]
    : []),
  ...(showOrganizationColumn
    ? [
        columnsHelper.accessor('organizationName', {
          cell: row => <TruncatedText text={row.getValue() ?? '-'} />,
          header: () => i18n._('Organization'),
          meta: {
            collapseAt: showMerchantColumn && showOrganizationColumn ? 'full' : 'md',
          },
        }),
      ]
    : []),
  columnsHelper.accessor(row => formatAmount(row.amount, locale, row.currency as CurrencyCode), {
    id: 'amount',
    header: props =>
      getTableMeta(props.table).breakpoints['2xl'] ? i18n._('Deposit Amount') : i18n._('Payout'),
    meta: {
      sort: {
        isSortable: true,
      },
      align: 'right',
    },
    size: TableColumnSize.S,
  }),
  columnsHelper.accessor('reference', {
    cell: row => row.getValue(),
    header: () => i18n._('Reference'),
    meta: {
      visibleAt: '3xl',
    },
    size: TableColumnSize.L,
  }),
  columnsHelper.accessor('bankAccountLast4', {
    cell: row => `******${row.getValue()}`,
    header: () => i18n._('Bank Account'),
    meta: {
      collapseAt: 'xl',
      visibleAt: 'sm',
      align: 'right',
    },
    size: TableColumnSize.XS,
  }),
  columnsHelper.accessor('bankAccountType', {
    cell: row => <span className="capitalize">{row.getValue()}</span>,
    header: () => i18n._('Account Type'),
    size: TableColumnSize.XS,
    meta: {
      visibleAt: 'lg',
    },
  }),
  columnsHelper.accessor('status', {
    cell: row => <span className="capitalize">{row.getValue()}</span>,
    header: () => i18n._('Status'),
    size: TableColumnSize.XS,
    meta: {
      visibleAt: '3xl',
    },
  }),
  columnsHelper.accessor('batchId', {
    header: () => i18n._('Batch ID'),
    cell: info =>
      info.row.original.batchId ? (
        <Link
          className="text-sm text-primary-600"
          to={`${activityDetailsPath}?batchId=${encodeURIComponent(info.row.original.batchId)}`}
        >
          {info.row.original.batchId}
        </Link>
      ) : (
        i18n._('N/A')
      ),
    size: TableColumnSize.M,
    meta: {
      visibleAt: '2xl',
    },
  }),
  columnsHelper.accessor('estimatedArrivalTime', {
    cell: props => {
      const val = props.getValue();
      return val ? formatLocaleDate(val) : 'N/A';
    },
    header: () => i18n._('Est. Arrival Date'),
    size: TableColumnSize.S,
    meta: {
      visibleAt: 'md',
    },
  }),
  columnsHelper.display({
    id: 'actions',
    cell: props => (
      <div className="flex justify-center">
        <DropDownMinimalMenuIcon
          items={[
            {name: i18n._('View Details'), onClick: () => openDetailsPanel(props.row.original)},
          ]}
        />
      </div>
    ),
    size: TableColumnSize.ACTIONS,
  }),
];
