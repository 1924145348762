import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {useLingui} from '@lingui/react';
import {DeclinedPaymentsFilters, MerchantAccountPublicStatus, RefusalReason} from '@zentact/common';
import {MerchantAccountStatus} from '@zentact/db';
import {useCallback, useState} from 'react';
import {MerchantAccountsPickerFilter} from '../dropdowns';
import {Button} from '../forms';
import {PaymentMethodIcon, paymentSourceMap} from '../icons';
import {displayPaymentSourceMap} from '../layout';
import {SlideOverWithBrandedHeader} from '../overlays';
import {ResetTableFiltersButton} from '../table';
import {CheckboxFilter} from './CheckboxFilter';
import {DashboardDatepicker} from './DashboardDatepicker';
import {EntityPickerFilter} from './EntityPickerFilter';
import {InputSearchFilter} from './InputSearchFilter';

type Props<T extends DeclinedPaymentsFilters> = {
  defaultFilters: T;
  typedSearchParams: T;
  setTypedSearchParams: (params: T) => void;
  selectedOrganization?: string;
  selectedMerchantAccount?: string;
  organizationList?: {id: string; name: string}[];
  merchantAccounts?: {
    id: string;
    businessName: string;
    status: MerchantAccountStatus | MerchantAccountPublicStatus;
    onboardedAt: string | null;
  }[];
  paymentMethod?: string[];
  pspReferenceId?: string;
  reference?: string;
  refusalReason?: string[];
  source?: string[];
  dateValue: {
    startDate: Date;
    endDate: Date;
  };
  handleDateChange: (dateRange: {
    startDate: Date;
    endDate: Date;
  }) => void;
  paymentMethodsFilters: string[];
  isPortal?: boolean;
};

export const DeclinedPaymentFilters = <T extends DeclinedPaymentsFilters>({
  defaultFilters,
  typedSearchParams,
  setTypedSearchParams,
  selectedOrganization,
  selectedMerchantAccount,
  merchantAccounts,
  paymentMethod,
  pspReferenceId,
  reference,
  source,
  dateValue,
  paymentMethodsFilters,
  organizationList,
  handleDateChange,
  refusalReason,
}: Props<T>) => {
  const {i18n} = useLingui();
  const [isOthersOpen, setIsOthersOpen] = useState(false);

  const handleFilterChange = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setTypedSearchParams({[key]: value} as unknown as T);
    },
    [setTypedSearchParams]
  );

  const handleFilterChangeWithClear = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setTypedSearchParams({...defaultFilters, [key]: value} as unknown as T);
    },
    [setTypedSearchParams]
  );

  const handleMerchantChange = useCallback(
    (value: string | undefined) => {
      handleFilterChange('selectedMerchantAccount', value);
    },
    [handleFilterChange]
  );

  return (
    <div className="flex flex-wrap items-center justify-end gap-2 lg:flex-nowrap">
      <div className="max-lg:w-full">
        <ResetTableFiltersButton
          ignoreKeys={[
            'endDate', // ignore endDate to show date range as a single applied date filter
          ]}
          defaultFilters={{}}
          activeFilters={typedSearchParams}
          setFilters={setTypedSearchParams}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        {organizationList && (
          <EntityPickerFilter
            selected={selectedOrganization || ''}
            onChange={value => handleFilterChange('selectedOrganization', value)}
            options={organizationList}
          />
        )}
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        {!!merchantAccounts?.length && (
          <div className="font-normal shrink-0 max-lg:w-full">
            <MerchantAccountsPickerFilter
              selectedMerchantAccount={selectedMerchantAccount || ''}
              onSelectMerchantAccount={handleMerchantChange}
              merchantAccountsOptions={merchantAccounts}
              allLabel={i18n._('Merchant')}
            />
          </div>
        )}
      </div>
      {paymentMethodsFilters.length > 0 && (
        <div className="flex-grow min-w-0 lg:hidden 2xl:block max-lg:w-full">
          <CheckboxFilter
            label="Payment Method"
            selected={paymentMethod}
            onChange={value => handleFilterChange('paymentMethod', value)}
            options={(paymentMethodsFilters ?? []).map(paymentMethod => ({
              element: <PaymentMethodIcon paymentType={paymentMethod} />,
              key: paymentMethod,
            }))}
          />
        </div>
      )}
      <div className="flex-grow min-w-0 lg:hidden 2xl:block max-lg:w-full">
        <InputSearchFilter
          label={i18n._('Transaction ID')}
          selected={pspReferenceId}
          onChange={value => handleFilterChangeWithClear('pspReferenceId', value)}
        />
      </div>
      <div className="flex-grow min-w-0 lg:hidden 3xl:block max-lg:w-full">
        <InputSearchFilter
          label={i18n._('Reference')}
          selected={reference}
          onChange={value => handleFilterChangeWithClear('reference', value)}
        />
      </div>
      <div className="lg:hidden 3xl:block max-lg:w-full">
        <CheckboxFilter
          label={i18n._('Source')}
          selected={source}
          onChange={value => handleFilterChange('source', value)}
          options={Object.entries(displayPaymentSourceMap).map(([key, value]) => ({
            element: (
              <div className="flex items-center gap-1 whitespace-nowrap">
                <span>{paymentSourceMap[key]}</span>
                {value}
              </div>
            ),
            key,
          }))}
        />
      </div>
      <div className="max-lg:w-full">
        <CheckboxFilter
          label={i18n._('Refusal Reason')}
          contentClass="right-0"
          selected={refusalReason}
          onChange={value => handleFilterChange('refusalReason', value)}
          options={Object.entries(RefusalReason).map(([key]) => ({
            element: (
              <div className="flex items-center gap-1 whitespace-nowrap">
                <span>{key}</span>
              </div>
            ),
            key,
          }))}
        />
      </div>
      <div className={'hidden lg:block max-lg:w-full'}>
        <Button
          type="button"
          size="sm"
          onClick={() => setIsOthersOpen(true)}
          className="w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
        >
          {i18n._('Others')}
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
            <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </span>
        </Button>
      </div>
      <div className="w-full mt-4 shrink-0 md:mt-0 lg:w-64">
        <DashboardDatepicker dateRange={dateValue} onChange={handleDateChange} />
      </div>
      <SlideOverWithBrandedHeader
        isOpen={isOthersOpen}
        title={i18n._('Others')}
        closeHandler={() => setIsOthersOpen(false)}
        panelClassName="w-screen pointer-events-auto lg:max-w-md"
      >
        <>
          {paymentMethodsFilters.length > 0 && (
            <div className="flex-grow min-w-0 2xl:hidden max-lg:w-full">
              <CheckboxFilter
                label={i18n._('Payment Method')}
                selected={paymentMethod}
                onChange={value => handleFilterChange('paymentMethod', value)}
                options={(paymentMethodsFilters ?? []).map(paymentMethod => ({
                  element: <PaymentMethodIcon paymentType={paymentMethod} />,
                  key: paymentMethod,
                }))}
              />
            </div>
          )}
          <div className="flex-grow min-w-0 2xl:hidden max-lg:w-full">
            <InputSearchFilter
              label={i18n._('Transaction ID')}
              selected={pspReferenceId}
              onChange={value => handleFilterChangeWithClear('pspReferenceId', value)}
            />
          </div>
          <div className="3xl:hidden max-lg:w-full">
            <InputSearchFilter
              label={i18n._('Merchant Reference ID')}
              selected={reference}
              onChange={value => handleFilterChangeWithClear('reference', value)}
            />
          </div>
          <div className="3xl:hidden max-lg:w-full">
            <CheckboxFilter
              label={i18n._('Source')}
              selected={source}
              onChange={value => handleFilterChange('source', value)}
              options={Object.entries(displayPaymentSourceMap).map(([key, value]) => ({
                element: (
                  <div className="flex items-center gap-1 whitespace-nowrap" key={`source-${key}`}>
                    <span>{paymentSourceMap[key]}</span>
                    {value}
                  </div>
                ),
                key,
              }))}
            />
          </div>
        </>
      </SlideOverWithBrandedHeader>
    </div>
  );
};
