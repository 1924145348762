import {useLingui} from '@lingui/react';
import {ChargebackListItemOutput} from '@zentact/api/src/trpc/routers/chargebackRouter';
import {PaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  capitalizeCustomAttribute,
  formatAmount,
  formatLocaleDate,
  formatPercentage,
} from '@zentact/common';
import {FlatPillWithDot} from '../../../elements';
import {PaymentMethodIcon} from '../../../icons';
import {displayPaymentStatusMap, paymentStatusToColor} from '../payment-list/columns';

type SidebarPaymentDetailsProps = {
  paymentRow: ChargebackListItemOutput['payment'] | PaymentListItemOutput | null;
  merchantAccountName: string;
  organizationName?: string;
  showProfit?: boolean;
  withFinalHorizontalDivide?: boolean;
};

export const SidebarPaymentDetails = ({
  paymentRow,
  merchantAccountName,
  showProfit,
  organizationName,
  withFinalHorizontalDivide,
}: SidebarPaymentDetailsProps) => {
  const {i18n} = useLingui();

  if (!paymentRow) {
    return null;
  }
  const {
    pspReferenceId,
    authorizedAmount,
    merchantSplitAmount,
    currency,
    status,
    paymentMethod,
    paymentMethodVariant,
    cardSummary,
    createdAt,
    ownerName,
    totalProcessingFee,
    paymentAttributes,
  } = paymentRow;

  const formattedAuthorizedAmount = formatAmount(
    authorizedAmount,
    i18n.locale as LocaleCode,
    currency as CurrencyCode
  );

  return (
    <div className="border-gray-100">
      <dl className="divide-y divide-gray-100">
        <div className="pb-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            {i18n._('PSP Transaction ID')}
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {pspReferenceId}
          </dd>
        </div>
        {!!organizationName && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Organization')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {organizationName}
            </dd>
          </div>
        )}
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            {i18n._('Merchant Account')}
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {merchantAccountName}
          </dd>
        </div>
        {!!ownerName && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Owner Name')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {ownerName}
            </dd>
          </div>
        )}
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            {i18n._('Authorized Amount')}
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {formattedAuthorizedAmount}
          </dd>
        </div>
        {showProfit && totalProcessingFee !== null && merchantSplitAmount !== null && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Profit')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatAmount(
                authorizedAmount - merchantSplitAmount - totalProcessingFee,
                i18n.locale as LocaleCode,
                currency as CurrencyCode
              )}{' '}
              (
              {formatPercentage(
                authorizedAmount,
                authorizedAmount - merchantSplitAmount - totalProcessingFee,
                i18n.locale as LocaleCode
              )}
              )
            </dd>
          </div>
        )}
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            {i18n._('Payment Method')}
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <div className="flex items-center gap-2">
              <PaymentMethodIcon paymentType={paymentMethod} />
              {cardSummary ? (
                <span>*{cardSummary}</span>
              ) : paymentMethod === 'ach' ? (
                <span>ACH</span>
              ) : (
                <span />
              )}
            </div>
          </dd>
        </div>
        {paymentMethodVariant !== paymentMethod && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Payment Method Variant')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div className="flex items-center gap-2">
                <span>{paymentMethodVariant}</span>
              </div>
            </dd>
          </div>
        )}
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Status')}</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <FlatPillWithDot
              color={paymentStatusToColor[status] || 'blue'}
              label={displayPaymentStatusMap[status] ?? status}
            />
          </dd>
        </div>
        {paymentRow.terminalId && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Terminal Id')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {paymentRow.terminalId}
            </dd>
          </div>
        )}
        {paymentRow.possibleEnhancedSchemeDataLevel && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._("Card's Possible Data Level")}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {paymentRow.possibleEnhancedSchemeDataLevel}(
              {paymentRow.submittedEnhancedSchemeDataLevel
                ? paymentRow.submittedEnhancedSchemeDataLevel ===
                  paymentRow.possibleEnhancedSchemeDataLevel
                  ? i18n._('Submitted')
                  : `${paymentRow.submittedEnhancedSchemeDataLevel} ${i18n._('Submitted')}`
                : i18n._('No data submitted')}
              )
            </dd>
          </div>
        )}
        {(paymentAttributes ?? []).map(attribute => (
          <div key={attribute.name} className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {capitalizeCustomAttribute(attribute.name)} (custom attribute)
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {attribute.value}
            </dd>
          </div>
        ))}
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Created')}</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {formatLocaleDate(createdAt)}
          </dd>
        </div>
        {!!withFinalHorizontalDivide && <div />}
      </dl>
    </div>
  );
};
