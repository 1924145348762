export const allIndustryGroups = {
  '11': 'Agriculture, forestry, fishing, and hunting',
  '2211': 'Electric power generation, transmission, and distribution',
  '2212': 'Natural gas distribution',
  '2213': 'Water, sewage, and other systems',
  '23': 'Construction & installation',
  '311': 'Food manufacturing',
  '3121A': 'Alcoholic beverage manufacturing',
  '3121B': 'Non-alcoholic beverage manufacturing',
  '3122':
    'Tobacco, e-cigarettes, vaping and e-liquids, vaping accessories, snus and smoking supplies',
  '313': 'Textile mills',
  '314': 'Textile product mills',
  '315': 'Apparel manufacturing',
  '316': 'Leather and allied product manufacturing',
  '321': 'Wood product manufacturing',
  '322': 'Paper manufacturing',
  '323': 'Printing and related support activities',
  '324': 'Petroleum and coal products manufacturing',
  '3254': 'Pharmaceutical and medicine manufacturing',
  '3255': 'Paint, coating, and adhesive manufacturing',
  '3259': 'Other chemical product and preparation manufacturing',
  '326': 'Plastics and rubber products manufacturing',
  '327': 'Nonmetallic mineral product manufacturing',
  '331': 'Primary metal manufacturing',
  '332': 'Fabricated metal product manufacturing',
  '333': 'Machinery manufacturing',
  '334': 'Computer and electronic product manufacturing',
  '335': 'Electrical equipment, appliance, and component manufacturing',
  '336': 'Transportation (equipment) manufacturing',
  '337': 'Furniture and related product manufacturing',
  '339A': 'Jewelry, precious stone, precious metal and silverware manufacturing',
  '339C': 'Medical equipment and supplies manufacturing',
  '339D': 'Other miscellaneous durable goods manufacturing',
  '339E': 'Other miscellaneous nondurable goods manufacturing',
  '4232': 'Furniture and home furnishing merchant wholesalers',
  '4233': 'Lumber and other construction materials merchant wholesalers',
  '42311': 'Automobile and other motor vehicle merchant wholesalers',
  '42312': 'Motor vehicle supplies and new parts merchant wholesalers',
  '42313': 'Tire and tube merchant wholesalers',
  '42314': 'Motor vehicle parts (used) merchant wholesalers',
  '42341': 'Photographic equipment and supplies merchant wholesalers',
  '42342': 'Office equipment merchant wholesalers',
  '42343': 'Computer and computer peripheral equipment and software merchant wholesalers',
  '42344': 'Other commercial equipment merchant wholesalers',
  '42345': 'Medical, dental, and hospital equipment and supplies merchant wholesalers',
  '42349': 'Other professional equipment and supplies merchant wholesalers',
  '4235': 'Metal and mineral (except petroleum) merchant wholesalers',
  '4236': 'Household appliances, electrical, and electronic goods merchant wholesalers',
  '4237': 'Hardware, plumbing, and heating equipment and supplies merchant wholesalers',
  '4238': 'Machinery, equipment, and supplies merchant wholesalers',
  '42394': 'Jewelry, watch, precious stone, and precious metal merchant wholesalers',
  '42399': 'Other miscellaneous durable goods merchant wholesalers',
  '4241': 'Paper and paper products merchant wholesalers',
  '4242': "Drugs and druggists' sundries merchant wholesalers",
  '4243': 'Apparel, piece goods, and notions merchant wholesalers',
  '4244': 'Grocery and related products merchant wholesalers',
  '4245': 'Farm product raw material merchant wholesalers',
  '4246': 'Chemical and allied products merchant wholesalers',
  '4247': 'Petroleum and petroleum products merchant wholesalers',
  '4248': 'Beer, wine, and distilled alcoholic beverage merchant wholesalers',
  '42491': 'Farm supplies merchant wholesalers',
  '42492': 'Book, periodical, and newspaper merchant wholesalers',
  '42493': "Flower, nursery stock, and florists' supplies merchant wholesalers",
  '42495': 'Paint, varnish, and supplies merchant wholesalers',
  '42499': 'Other miscellaneous nondurable goods merchant wholesalers',
  '42511': 'Business-to-business electronic markets',
  '42512': 'Wholesale trade agents and brokers',
  '44111': 'New car dealers',
  '4411B': 'Used cars - reputable dealers',
  '44121': 'Recreational vehicle dealers',
  '4412A': 'Mobile home dealers',
  '4412B': 'Utility trailer dealers',
  '441222': 'Boat dealers',
  '441228': 'Motorcycle, ATV, and all other motor vehicle dealers',
  '44132': 'Tire dealers',
  '442A': 'Furniture stores',
  '442B': 'Home furnishings stores',
  '443141': 'Household appliance stores',
  '443142': 'Electronics stores',
  '4431A': 'Computer software stores',
  '4431B': 'Telecommunication Equipment and Telephone Stores',
  '44411': 'Home centers',
  '44412': 'Paint and wallpaper stores',
  '44413': 'Hardware stores',
  '44419': 'Other building material stores',
  '4451': 'Grocery stores, Supermarkets',
  '4452': 'Specialty food stores',
  '4453': 'Beer, wine, and liquor stores',
  '311811': 'Bakeries',
  '4461A': 'Nutraceutical stores (non-food)',
  '44611': 'Pharmacies and drug stores',
  '44612': 'Cosmetics, beauty supplies, and perfume stores',
  '44613': 'Optical goods store',
  '44619A': 'Food (Health) supplement stores (nutraceuticals, food) - regulated',
  '446191': 'Food (Health) supplement stores (nutraceuticals, food) - non-regulated',
  '446199': 'All other health and personal care stores',
  '447': 'Gasoline stations',
  '44811': "Men's clothing stores",
  '44812': "Women's clothing stores",
  '4481A': "Men's and Women's clothing stores",
  '44813': "Children's and Infants' clothing stores",
  '44814': 'Family clothing stores',
  '4481B': 'Sports and riding apparel stores',
  '44819': 'Other Clothing (accessories) stores',
  '4482': 'Shoe stores',
  '44831': 'Jewelry stores',
  '44832': 'Luggage and leather goods stores',
  '45111': 'Sporting goods stores',
  '45112': 'Hobby, toy, and game stores',
  '45113': 'Sewing, needlework, and piece Goods stores',
  '45114': 'Musical instrument and supplies stores',
  '4512': 'Book stores and news dealers',
  '4522': 'Department stores',
  '4523': 'General merchandise stores, including warehouse clubs and supercenters',
  '452A': 'Buying and shopping services and clubs',
  '4531': 'Florists',
  '45321': 'Office supplies and stationery stores',
  '45322': 'Gift, novelty, and souvenir stores',
  '4533': 'Used merchandise stores',
  '45391': 'Pet and Pet Supplies stores',
  '45392': 'Art dealers',
  '45393': 'Manufactured (mobile) home dealers',
  '4539B': 'Stamp and coin stores',
  '4539D': 'Auction houses',
  '4539H': 'Discount stores',
  '4539I': 'Duty-free stores',
  '4539J': 'Second hand stores',
  '4539K': 'Antique stores',
  '453998': 'All other miscellaneous store retailers',
  '4542A': 'Vending machines (Food)',
  '4542B': 'Vending machines (Non-Food)',
  '45431': 'Fuel dealers',
  '481B': 'Other air transportation',
  '482A': 'Passenger rail transportation',
  '482B': 'Freight rail transportation',
  '483B': 'Ferries',
  '483C': 'Water Freight',
  '484': 'Truck transportation',
  '4851': 'Urban transit systems',
  '4852': 'Interurban and rural bus transportation',
  '4853': 'Taxi and limousine service',
  '4854': 'School and employee bus transportation',
  '4855': 'Charter bus industry',
  '4859': 'Other transit and ground passenger transportation',
  '487': 'Scenic and sightseeing transportation',
  '4881A': 'Airports, airport terminals, flying fields',
  '48819': 'Other support activities for air transportation',
  '4882': 'Support activities for rail transportation',
  '4883': 'Support Activities for water Transportation',
  '48841': 'Motor vehicle towing',
  '4884A': 'Bridge and road fees, tolls',
  '4884B': 'Electric vehicle charging',
  '48849': 'Other support activities for road transportation',
  '4889': 'Other support activities for transportation',
  '491': 'Postal service',
  '492': 'Couriers and messengers',
  '493': 'Warehousing and storage',
  '5111': 'Newspaper, periodical, book, and directory publishers (except internet)',
  '5112A': 'Digital goods - audiovisual media including books, movies, and music',
  '5112B': 'Digital goods - games',
  '51121': 'Digital goods - software applications',
  '51211': 'Motion picture and video production',
  '51212': 'Motion picture and video distribution',
  '51213': 'Motion picture and video exhibition',
  '5122': 'Sound recording industries',
  '515': 'Broadcasting (except internet)',
  '517311': 'Wired and Wireless Telecommunications Carriers',
  '51731B': 'Wireless telecommunications carriers - Prepaid',
  '51731D': 'Other wireless telecommunications carriers',
  '5179': 'Other telecommunications',
  '517911': 'Telecommunications Resellers',
  '51911': 'News Agency',
  '51913': 'Internet publishing and broadcasting and web search portals',
  '5191A': 'All other information services',
  '52231': 'Mortgage and non-mortgage loan brokers',
  '52412': 'Direct insurance (except life, health, and medical) carriers',
  '524114': 'Direct health and medical insurance carriers',
  '52413': 'Reinsurance carriers',
  '5242': 'Agencies, brokerages, and other insurance related activities',
  '53211': 'Passenger car rental and leasing',
  '53212': 'Truck, and utility trailer rental and leasing',
  '5321B': 'Motor home and recreational vehicle rental',
  '53221': 'Consumer electronics and appliances rental',
  '532281': 'Formal wear and costume rental',
  '532282': 'Video tape and disc rental',
  '532283': 'Home health equipment rental',
  '532284': 'Recreational goods rental',
  '532289': 'All other consumer goods rental',
  '5324': 'Commercial, industrial machinery and equipment rental and leasing',
  '5411A': 'Lawyers (except bankruptcy)',
  '541211': 'Offices of certified public accountants',
  '541213': 'Tax preparation services',
  '5413': 'Architectural, engineering, and related services',
  '5414': 'Specialized design services',
  '5415': 'Computer systems design and related services',
  '5416': 'Management, scientific, and technical consulting services',
  '54171': 'Research and development in the Physical, Engineering, and Life Sciences',
  '5417A': 'Ancestry research',
  '5417B': 'Other research and development in the Social Sciences and Humanities',
  '5418': 'Advertising, public relations, and related services',
  '54192': 'Photographic services',
  '54194': 'Veterinary services',
  '54199': 'All other professional, scientific, and technical services',
  '55': 'Management of companies and enterprises',
  '5611': 'Office administrative services',
  '5612': 'Facilities support services',
  '5613': 'Employment services',
  '56141': 'Document preparation services',
  '56142': 'Telephone call centers',
  '56143': 'Business service centers',
  '56145': 'Credit bureaus',
  '56149': 'Other business support services',
  '5615': 'Travel arrangement and reservation services',
  '5616A': 'Security services',
  '56171': 'Exterminating and pest control services',
  '56172': 'Janitorial services',
  '56173': 'Landscaping services',
  '56174': 'Carpet and upholstery cleaning services',
  '56179': 'Other services to buildings and dwellings',
  '5619': 'Other support services',
  '562': 'Waste management and remediation services',
  '5912': 'Drug Stores / Pharmacy',
  '6111': 'Elementary and secondary schools',
  '6112': 'Junior colleges',
  '6113': 'Colleges, universities, and professional schools',
  '6114': 'Business schools, and computer and management training',
  '6115': 'Technical and trade schools',
  '6116A': 'Dance Schools',
  '61161': 'Fine arts schools',
  '61162': 'Sports and recreation instruction',
  '61163': 'Language schools',
  '61169': 'All other schools and instruction',
  '6211': 'Office of physicians',
  '6212': 'Office of dentists',
  '62131': 'Office of chiropractors',
  '62132': 'Offices of optometrists',
  '62133': 'Offices of mental health practitioners',
  '62134': 'Offices of physical, occupational and speech therapists, and audiologists',
  '62139': 'Offices of all other health practitioners',
  '6214': 'Outpatient care centers',
  '6215': 'Medical and diagnostic laboratories',
  '6216': 'Home health care services',
  '6219': 'Other ambulatory health care services',
  '621A': 'Commercial private health and e-doctor services',
  '622': 'Hospitals',
  '623': 'Nursing and residential care facilities',
  '6241': 'Individual and family services',
  '6242': 'Community food and housing, and emergency and other relief services',
  '6243': 'Vocational rehabilitation services',
  '6244': 'Child day care services',
  '624A': 'All other social services',
  '7111': 'Performing arts companies',
  '7112': 'Spectator sports',
  '7113': 'Promoters of performing arts, sports, and similar events',
  '7114': 'Agents and managers for artists, athletes, entertainers, and other public figures',
  '7115': 'Independent artists, writers, and performers',
  '711A': 'Ticketing agencies',
  '712': 'Museums, historical sites, and similar institutions',
  '71311': 'Amusement and theme parks',
  '71312': 'Amusement arcades',
  '7139A': 'Public golf courses',
  '7139B': 'Private golf courses and country clubs',
  '71392': 'Skiing facilities',
  '71393': 'Marinas',
  '71394': 'Fitness and recreational sports centers',
  '71395': 'Bowling centers',
  '7139C': 'All other amusement and recreation industries',
  '7139D': 'Billiard and pool establishments',
  '7139E': 'Aquariums, seaquariums, dolphinariums, and zoos',
  '72111': 'Hotels (except casino hotels) and motels',
  '7211A': 'Timeshares',
  '7211B': 'Other traveler accommodation',
  '7212': 'Recreational vehicle parks and recreational camps',
  '7213': "Rooming and boarding houses, dormitories, and workers' Camps",
  '7223': 'Special food services',
  '7224': 'Drinking places (alcoholic beverages)',
  '722511': 'Full-service restaurants',
  '722513': 'Limited-service restaurants (Fast food restaurants)',
  '722514': 'Cafeterias, grill buffets, and buffets',
  '722515': 'Snacks and nonalcoholic beverage bars',
  '8111': 'Automotive repair and maintenance',
  '8112': 'Electronic and precision equipment repair and maintenance (including computers)',
  '8113':
    'Commercial and industrial machinery and equipment (except automotive and electronic) repair and maintenance',
  '8114': 'Personal and household goods repair and maintenance',
  '81211': 'Hair, nail, and skin care services',
  '8121A': 'Massage parlors',
  '8121B': 'Health and beauty spas',
  '8122': 'Death care services',
  '8123': 'Drycleaning and Laundry services',
  '8129C':
    'Pseudo science services (astrology, psychokinesis, clairvoyance, fortune telling, etc.)',
  '8129E': 'Other personal services',
  '8134': 'Civic and social organizations',
  '81391': 'Business associations',
  '81392': 'Professional organizations',
  '81393': 'Labor unions and similar labor organizations',
  '81394': 'Political organizations',
  '81399':
    'Other similar organizations (except business, professional, labor, and political organizations)',
  '921': 'Executive, legislative, and other general government support',
  '922A': 'Fines and penalties of any kind',
  '92219': 'Other justice, public order, and safety activities',
  '92B': 'Other public administrations',
};
