export enum LocaleCode {
  // biome-ignore lint/style/useNamingConvention: Locale convention
  ps_DO = 'ps-DO',
  // biome-ignore lint/style/useNamingConvention: Locale convention
  en_US = 'en-US',
  // biome-ignore lint/style/useNamingConvention: Locale convention
  en_GB = 'en-GB',
  // biome-ignore lint/style/useNamingConvention: Locale convention
  en_AU = 'en-AU',
  // biome-ignore lint/style/useNamingConvention: Locale convention
  en_CA = 'en-CA',
  // biome-ignore lint/style/useNamingConvention: Locale convention
  en_NZ = 'en-NZ',
}

export const DEFAULT_LOCALE = LocaleCode.en_US;
