import {LanguageCode, LocaleCode} from '../constants/enums';

const paymentMethodNameMap: {[key: string]: {[K in LanguageCode | LocaleCode]?: string}} = {
  mc: {
    [LanguageCode.en]: 'Mastercard',
    [LanguageCode.ps]: 'Mastercard',
  },
  visa: {
    [LanguageCode.en]: 'Visa',
    [LanguageCode.ps]: 'Visa',
  },
  amex: {
    [LanguageCode.en]: 'American Express',
    [LanguageCode.ps]: 'American Express',
  },
  discover: {
    [LanguageCode.en]: 'Discover',
    [LanguageCode.ps]: 'Discover',
  },
  // biome-ignore lint/style/useNamingConvention: Special case
  mc_applepay: {
    [LanguageCode.en]: 'Apple Pay Mastercard',
    [LanguageCode.ps]: 'Apple Pay Mastercard',
  },
  // biome-ignore lint/style/useNamingConvention: Special case
  visa_applepay: {
    [LanguageCode.en]: 'Apple Pay Visa',
    [LanguageCode.ps]: 'Apple Pay Visa',
  },
  // biome-ignore lint/style/useNamingConvention: Special case
  discover_applepay: {
    [LanguageCode.en]: 'Apple Pay Discover',
    [LanguageCode.ps]: 'Apple Pay Discover',
  },
  // biome-ignore lint/style/useNamingConvention: Special case
  amex_applepay: {
    [LanguageCode.en]: 'Apple Pay American Express',
    [LanguageCode.ps]: 'Apple Pay American Express',
  },
  ach: {
    [LanguageCode.en]: 'ACH',
    [LanguageCode.ps]: 'ACH',
  },
};

export const getPaymentMethodName = (
  paymentMethod: string,
  locale: LocaleCode = LocaleCode.en_US
) => {
  const localeName = paymentMethodNameMap[paymentMethod]?.[locale];

  const languageCode = locale.split('-')[0] as LanguageCode;
  const languageName = paymentMethodNameMap[paymentMethod]?.[languageCode];

  return localeName ?? languageName ?? paymentMethod;
};
