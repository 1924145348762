import {Popover} from '@headlessui/react';
import {FunnelIcon} from '@heroicons/react/24/outline';
import React, {ReactElement, cloneElement, useState} from 'react';
import {usePopper} from 'react-popper';
import {cn} from '../..';
import Portal from '../../overlays/Portal';

export type TableFilterWrapperProps = {
  children: React.ReactNode;
  isActive: boolean;
  icon?: ReactElement;
  buttonClass?: string;
  contentClass?: string;
  focus?: boolean;
};

export const TableFilterWrapper = ({
  children,
  isActive,
  icon = <FunnelIcon />,
  buttonClass,
  contentClass,
  focus,
}: TableFilterWrapperProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  });

  return (
    <Popover>
      <Popover.Button
        ref={setReferenceElement}
        className={cn(
          'focus-visible:outline-primary-600 rounded-sm py-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
          buttonClass
        )}
      >
        {icon &&
          cloneElement(icon, {
            className: cn('h-4 w-4', isActive ? 'text-primary-600' : 'text-gray-500'),
          })}
      </Popover.Button>

      <Portal>
        <Popover.Panel
          focus={focus}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={cn(
            'z-50 mt-1 max-h-64 min-w-min overflow-auto rounded-md bg-white p-4 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm',
            contentClass
          )}
        >
          {children}
        </Popover.Panel>
      </Portal>
    </Popover>
  );
};
