import {trpc} from '@/api/trpcClient';
import {useAddressAutocomplete} from '@/hooks/useAddressAutocomplete';
import {useStore} from '@/store';
import {AddressLookupItem} from '@adyen/adyen-web/dist/types/components/internal/Address/types';
import {useAuth0} from '@auth0/auth0-react';
import {ArrowUpRightIcon, CheckIcon} from '@heroicons/react/20/solid';
import {zodResolver} from '@hookform/resolvers/zod';
import {I18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  DEFAULT_CURRENCY,
  ErrorCode,
  allIndustryGroups,
  allowedTimezoneList,
  generalTimezones,
  isFormattedTrpcError,
  isValidPhoneNumber,
  maxAchSurchargePercentageFee,
  maxCreditCardFlatFee,
  maxCreditCardSurchargePercentageFee,
  maxInteracFlatFee,
  supportedDocumentFileExtensions,
  toMinorUnits,
} from '@zentact/common';
import {isValidUrl} from '@zentact/common';
import {
  Button,
  ContentCard,
  InputCheckbox,
  InputPhone,
  InputSearchSelect,
  InputSelect,
  InputText,
  Label,
  Typeahead,
  Typography,
  ValidationError,
  getSurchargeConfigurationScheme,
  useNotification,
} from '@zentact/ui-tailwind';
import {nzStateAbbreviations} from '@zentact/ui-tailwind/constants/address/nzStateAbbreviations';
import {cn} from '@zentact/ui-tailwind/utils';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import z from 'zod';
import {NAME_SURNAME_REGEX, merchantRegistrationStepTitles} from '../../constants';
import {UploadApplicationDocument} from './upload-application-document';

const getMerchantApplicationSchema = (
  i18n: I18n,
  requireDocumentUpload: boolean,
  hasOnlinePresence: boolean,
  isOrganizationSupportEmailMissing?: boolean
) => {
  return z
    .object({
      legalEntityType: z.enum(['organization', 'soleProprietorship'], {
        // biome-ignore lint/style/useNamingConvention: Zod uses camel case
        required_error: t`Please select your organization type`,
      }),
      estimatedMonthlyVolume: z.enum(
        [
          '0-5000',
          '5001-9999',
          '10000-24999',
          '25000-49999',
          '50000-99999',
          '100000-249999',
          '250000',
        ],
        {
          // biome-ignore lint/style/useNamingConvention: Zod uses camel case
          required_error: t`Please select your estimated monthly volume`,
        }
      ),
      estimatedAverageTicket: z.enum(['0-99', '100-249', '250-499', '500-999', '1000'], {
        // biome-ignore lint/style/useNamingConvention: Zod uses camel case
        required_error: t`Please select your estimated average ticket`,
      }),
      legalBusinessName: z.string().min(1, t`Legal business name required`),
      firstName: z
        .string()
        .regex(NAME_SURNAME_REGEX, t`Invalid First Name`)
        .min(2, t`First Name must contain at least 2 character(s)`)
        .max(18, t`First Name must contain at most 18 character(s)`),
      lastName: z
        .string()
        .regex(NAME_SURNAME_REGEX, t`Invalid Last Name`)
        .min(2, t`Last Name must contain at least 2 character(s)`)
        .max(18, t`Last Name must contain at most 18 character(s)`),
      country: z.string().min(1, t`Country is required`),
      businessPhoneNumber: z
        .string({
          // biome-ignore lint/style/useNamingConvention: <explanation>
          invalid_type_error: t`Phone number is invalid`,
        })
        .min(1, t`Phone number is required`)
        .refine(phoneNumber => isValidPhoneNumber(phoneNumber), {
          message: t`Phone number is invalid`,
        }),
      shopperStatement: z
        .string()
        .min(1, t`Shopper statement is required`)
        .max(22, t`Shopper statement cannot be longer than 22 characters`)
        .regex(/\D/, t`Shopper statement cannot be all digits`),
      businessAddress: z
        .object({
          line1: z.string().min(1, t`Line 1 is required`),
          line2: z.string().optional(),
          city: z.string().min(1, t`City is required`),
          state: z
            .union([
              z
                .string()
                .min(2, t`State or Province is required`)
                .max(3, t`Please use two or three letter abbreviation for the state`),
              z.string().length(0),
            ])

            .optional(),
          //Some countrise like UK don't have states or provinces
          postalCode: z.string().min(1, t`Postal code is required`),
        })
        .required(),
      webAddress: hasOnlinePresence
        ? isValidUrl(t`Web address is invalid`)
        : z
            .string()
            .transform(() => undefined)
            .optional(),
      timeZone: z
        .string()
        .default(Intl.DateTimeFormat().resolvedOptions().timeZone)
        // TODO: this should be required, see ZEN-195
        .refine(timezone => timezone && generalTimezones.has(timezone), {
          message: t`timezone is not valid`,
        }),
      mccCode: z.string().min(1, t`Industry is required`),

      supportEmail: z
        .string()
        .email(t`Please enter a valid email address`)
        .max(50, t`Email address must not exceed 50 characters`)
        .optional()
        .refine(supportEmail => !(isOrganizationSupportEmailMissing && !supportEmail), {
          message: t`Please enter a support email address`,
        }),

      uploadedDocumentName: z
        .string()
        .optional()
        .refine(uploadedDocumentName => !(requireDocumentUpload && !uploadedDocumentName), {
          message: t`The document file is missing. Please upload the required document to continue`,
        }),
      surchargeConfigurationViaAdditionalCommission: z.object({
        creditCardPercentageFee: z.coerce
          .number({
            // biome-ignore lint/style/useNamingConvention: Zod uses snake case
            invalid_type_error: t`Only numbers and decimals are allowed`,
          })
          .multipleOf(0.01, t`Fee must be a multiple of 0.01`)
          .min(0)
          .max(
            maxCreditCardSurchargePercentageFee,
            t`Percentage fee can not be higher than ${maxCreditCardSurchargePercentageFee}%`
          )
          .default(0),
        creditCardFlatFee: z.coerce
          .number({
            // biome-ignore lint/style/useNamingConvention: Zod uses snake case
            invalid_type_error: t`Only numbers and decimals are allowed`,
          })
          .multipleOf(0.01, t`Fee must be a multiple of 0.01`)
          .min(0)
          .max(
            maxCreditCardFlatFee,
            t`Credit card flat fee can not be higher than ${maxCreditCardFlatFee}`
          )
          .default(0),
        interacFlatFee: z.coerce
          .number({
            // biome-ignore lint/style/useNamingConvention: Zod uses snake case
            invalid_type_error: t`Only numbers and decimals are allowed`,
          })
          .multipleOf(0.01, t`Fee must be a multiple of 0.01`)
          .min(0)
          .max(
            maxInteracFlatFee,
            t`Max interac flat fee can not be higher than ${maxInteracFlatFee}`
          )
          .default(0),
      }),
      surchargeConfiguration: getSurchargeConfigurationScheme({i18n}).optional(),
    })
    .superRefine((data, ctx) => {
      if (data.country !== 'GB' && !data.businessAddress.state) {
        ctx.addIssue({
          path: ['businessAddress', 'state'],
          code: z.ZodIssueCode.custom,
          message: t`State or Province is required`,
        });
      }
    });
};

export type MerchantApplicationForm = z.infer<ReturnType<typeof getMerchantApplicationSchema>>;
type FormLegalEntityType = MerchantApplicationForm['legalEntityType'];

type Props = {
  onComplete: (redirectUrl: string) => void;
  registrationSessionId: string;
  uploadedDocumentName: string | null;
  refetchRegistrationStatus: () => void;
};

const parseBounds = (id: string) => {
  if (id.includes('-')) {
    const [lower, upper] = id.split('-').map(Number);
    return {lowerBound: lower, upperBound: upper};
  }
  return {lowerBound: Number(id), upperBound: null};
};

export const MerchantApplication = ({
  onComplete,
  registrationSessionId,
  uploadedDocumentName,
  refetchRegistrationStatus,
}: Props) => {
  const {i18n} = useLingui();
  const [hasOnlinePresence, setHasOnlinePresence] = useState(true);
  const formDefaults = trpc.merchantRegistration.step3FormDefaults.useQuery(
    {
      registrationSessionId,
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: 'always',
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  const {tenant} = useStore();
  const formMethods = useForm<MerchantApplicationForm>({
    resolver: zodResolver(
      getMerchantApplicationSchema(
        i18n,
        !!tenant?.features?.askForDocumentsInMerchantApplication,
        hasOnlinePresence,
        formDefaults.data?.isOrganizationSupportEmailMissing
      )
    ),
    defaultValues: {
      surchargeConfigurationViaAdditionalCommission: {
        creditCardPercentageFee: 0,
        creditCardFlatFee: 0,
        interacFlatFee: 0,
      },
      surchargeConfiguration: {
        surchargeCreditCardEnabled: false,
        surchargeAchEnabled: false,
        creditCardPercentageFee: 0,
        creditCardFlatFee: 0,
        achPercentageFee: 0,
        achFlatFee: 0,
      },
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    setError,
    watch,
    formState: {errors, isSubmitted},
  } = formMethods;
  const {user} = useAuth0();
  const {showErrorNotification} = useNotification();
  const [showPDF, setShowPDF] = useState(false);
  const [readonlyName, setReadonlyName] = useState(false);

  const [predictions, setPredictions] = useState<AddressLookupItem[]>([]);

  const surchargeFeatureEnabled = !!tenant?.features?.enableSurcharges;
  const surchargeViaAdditionalCommissionFeatureEnabled =
    !!tenant?.features?.enableSurchargesViaAdditionalCommission;

  const surchargeConfiguration = watch('surchargeConfiguration');

  const {initialize: initGoogleMapsApi, getAddressPredictions} = useAddressAutocomplete(
    formDefaults.data?.countryCode ? [formDefaults.data.countryCode] : undefined
  );

  useEffect(() => {
    initGoogleMapsApi();
  }, []);

  useEffect(() => {
    if (isSubmitted) {
      trigger('webAddress');
    }
  }, [hasOnlinePresence, trigger, isSubmitted]);

  useEffect(() => {
    register('legalEntityType');
    register('businessPhoneNumber', {required: true});
  }, [register]);

  useEffect(() => {
    if (!formDefaults.data) return;

    setValue('firstName', formDefaults.data.firstName || '', {shouldDirty: true});
    setValue('lastName', formDefaults.data.lastName || '', {shouldDirty: true});
    setReadonlyName(formDefaults.data.firstName && formDefaults.data.lastName ? true : false);
    setValue('legalBusinessName', formDefaults.data.legalBusinessName || '', {shouldDirty: true});
    setValue('businessPhoneNumber', formDefaults.data.businessPhoneNumber || '', {
      shouldDirty: true,
    });

    setValue('businessAddress.line1', formDefaults.data.businessAddress?.line1 || '', {
      shouldDirty: true,
    });
    setValue('businessAddress.line2', formDefaults.data.businessAddress?.line2 || '', {
      shouldDirty: true,
    });
    setValue('businessAddress.city', formDefaults.data.businessAddress?.city || '', {
      shouldDirty: true,
    });
    setValue('businessAddress.state', formDefaults.data.businessAddress?.state || '', {
      shouldDirty: true,
    });
    setValue('businessAddress.postalCode', formDefaults.data.businessAddress?.postalCode || '', {
      shouldDirty: true,
    });
    setValue('country', formDefaults.data.countryCode, {
      shouldDirty: true,
    });
    setValue(
      'webAddress',
      formDefaults.data.webAddress ? formDefaults.data.webAddress.replace('https://', '') : '',
      {
        shouldDirty: true,
      }
    );
    setValue('shopperStatement', formDefaults.data.shopperStatement || '', {
      shouldDirty: true,
    });
    if (formDefaults.data.legalEntityType) {
      setValue('legalEntityType', formDefaults.data.legalEntityType as FormLegalEntityType, {
        shouldDirty: true,
      });
    }
    if (formDefaults.data.industryCodes?.[0]) {
      setValue('mccCode', formDefaults.data.industryCodes[0], {shouldDirty: true});
    }

    if (formDefaults.data.isOrganizationSupportEmailMissing && formDefaults.data.supportEmail) {
      setValue('supportEmail', formDefaults.data.supportEmail, {
        shouldDirty: true,
      });
    }

    setValue('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone, {shouldDirty: true});

    if (formDefaults.data.surchargeConfigurationViaAdditionalCommission) {
      setValue(
        'surchargeConfigurationViaAdditionalCommission',
        formDefaults.data.surchargeConfigurationViaAdditionalCommission,
        {
          shouldDirty: true,
        }
      );
    }
    if (formDefaults.data.surchargeConfiguration) {
      setValue('surchargeConfiguration', formDefaults.data.surchargeConfiguration, {
        shouldDirty: true,
      });
    }
  }, [formDefaults.data, setValue]);

  useEffect(() => {
    setValue('uploadedDocumentName', uploadedDocumentName || '', {shouldValidate: isSubmitted});
  }, [uploadedDocumentName, setValue]);

  useEffect(() => {
    if (!surchargeConfiguration) {
      return;
    }
    if (!surchargeConfiguration.surchargeAchEnabled) {
      setValue('surchargeConfiguration.achPercentageFee', 0);
      setValue('surchargeConfiguration.achFlatFee', 0);
    }
    if (!surchargeConfiguration.surchargeCreditCardEnabled) {
      setValue('surchargeConfiguration.creditCardFlatFee', 0);
      setValue('surchargeConfiguration.creditCardPercentageFee', 0);
    }
  }, [
    surchargeConfiguration?.surchargeAchEnabled,
    surchargeConfiguration?.surchargeCreditCardEnabled,
  ]);

  const signUpMutation = trpc.merchantRegistration.step3StartMerchantAccount.useMutation({
    onSuccess(data) {
      onComplete(data.redirectUrl);
    },
    onError(error) {
      if (isFormattedTrpcError(error)) {
        if (error.data.errorCode === ErrorCode.STATE_IS_INVALID) {
          setError('businessAddress.state', {
            type: 'manual',
            message: error.message || t`State/Province is invalid`,
          });
          showErrorNotification(t`Error`, error.message || t`State/Province is invalid`);
          return;
        }
        if (error.data.errorCode === ErrorCode.POSTAL_CODE_IS_INVALID) {
          setError('businessAddress.postalCode', {
            type: 'manual',
            message: error.message || t`Postal code is invalid`,
          });
          showErrorNotification(t`Error`, error.message || t`Postal code is invalid`);
          return;
        }
        if (error.data.errorCode === ErrorCode.WEB_ADDRESS_IS_INVALID) {
          setError('webAddress', {
            type: 'manual',
            message: t`Web address is invalid`,
          });
          showErrorNotification(t`Error`, t`Web address is invalid`);
          return;
        }
        if (
          error.data.errorCode === ErrorCode.PHONE_NUMBER_IS_INVALID ||
          (error.data.errorCode === ErrorCode.SCHEMA_VALIDATION_ERROR &&
            error.data.issues?.find(issue => issue.path?.[0] === 'businessPhoneNumber'))
        ) {
          setError('businessPhoneNumber', {
            type: 'manual',
            message: t`Invalid phone number`,
          });
          showErrorNotification(t`Error`, t`Invalid phone number`);
          return;
        }
      }

      if (error.message) {
        showErrorNotification(t`Error`, error.message);
      } else {
        showErrorNotification(
          t`Error`,
          t`Failed to launch merchant application, please contact support`
        );
      }
    },
  });

  const onSubmit = useCallback(
    (data: MerchantApplicationForm) => {
      const monthlyVolumeBounds = parseBounds(data.estimatedMonthlyVolume);
      const averageTicketBounds = parseBounds(data.estimatedAverageTicket);
      signUpMutation.mutate({
        firstName: data.firstName,
        lastName: data.lastName,
        businessPhoneNumber: data.businessPhoneNumber,
        shopperStatement: data.shopperStatement,
        legalBusinessName: data.legalBusinessName,
        legalEntityType: data.legalEntityType,
        estimateMonthlyVolumeUpperBound: monthlyVolumeBounds.upperBound,
        estimateMonthlyVolumeLowerBound: monthlyVolumeBounds.lowerBound,
        estimateAverageTicketUpperBound: averageTicketBounds.upperBound,
        estimateAverageTicketLowerBound: averageTicketBounds.lowerBound,

        businessAddress: data.businessAddress,
        webAddress: data.webAddress,
        timeZone: data.timeZone,
        registrationSessionId,
        mccCode: data.mccCode,
        supportEmail: data.supportEmail,
        ...(surchargeViaAdditionalCommissionFeatureEnabled && {
          surchargeConfigurationViaAdditionalCommission: {
            creditCardPercentageFee:
              data.surchargeConfigurationViaAdditionalCommission.creditCardPercentageFee,
            creditCardFlatFee: toMinorUnits(
              data.surchargeConfigurationViaAdditionalCommission.creditCardFlatFee,
              DEFAULT_CURRENCY
            ),
            interacFlatFee: toMinorUnits(
              data.surchargeConfigurationViaAdditionalCommission.interacFlatFee,
              DEFAULT_CURRENCY
            ),
          },
        }),
        ...(surchargeFeatureEnabled && {
          surchargeConfiguration: {
            ...data.surchargeConfiguration,
            creditCardFlatFee: data.surchargeConfiguration?.creditCardFlatFee
              ? toMinorUnits(data.surchargeConfiguration.creditCardFlatFee, DEFAULT_CURRENCY)
              : 0,
            achFlatFee: data.surchargeConfiguration?.achFlatFee
              ? toMinorUnits(data.surchargeConfiguration.achFlatFee, DEFAULT_CURRENCY)
              : 0,
          },
        }),
      });
    },
    [
      signUpMutation,
      tenant?.features?.askForDocumentsInMerchantApplication,
      uploadedDocumentName,
      showErrorNotification,
      surchargeFeatureEnabled,
      surchargeViaAdditionalCommissionFeatureEnabled,
    ]
  );

  const timezoneOptions = [...allowedTimezoneList].map(timeZone => {
    const timeZoneData = Intl.DateTimeFormat(undefined, {
      timeZone,
      timeZoneName: 'short',
    })
      .formatToParts(new Date())
      .find(part => part.type === 'timeZoneName');

    const utcOffset = timeZoneData?.value || 'Unknown';

    return {
      id: timeZone,
      label: `(${utcOffset}) ${timeZone}`,
    };
  });

  const mccCodeOptions = useMemo(() => {
    if (!formDefaults.data?.industryCodes) return [];

    return formDefaults.data.industryCodes.map(industryCode => ({
      id: industryCode,
      label:
        allIndustryGroups[industryCode as keyof typeof allIndustryGroups] ||
        'Incorrectly configured industry',
    }));
  }, [formDefaults.data?.industryCodes]);

  const handleAddressLineChange = async (address: string) => {
    setValue('businessAddress.line1', address);
    if (address.length === 0) return;
    const predictionItems = await getAddressPredictions(address);
    setPredictions(predictionItems);
  };

  const onSelectAddress = (value: string, onChange: (v: string) => void) => {
    const addressId = Array.isArray(value) ? value[0] : value;
    const address = predictions.find(prediction => prediction.id === addressId);
    if (!address) {
      onChange(value);
      return;
    }
    const stateOrProvince =
      address.country === 'NZ' && address.stateOrProvince
        ? nzStateAbbreviations[address.stateOrProvince]
        : address.stateOrProvince;

    onChange(address.street || '');
    setValue('businessAddress.city', address.city || '', {shouldValidate: true});
    if (address.country !== 'GB') {
      setValue('businessAddress.state', stateOrProvince || '', {shouldValidate: true});
    }
    setValue('businessAddress.postalCode', address.postalCode || '', {shouldValidate: true});
  };

  return (
    <form
      className="mb-20 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
        <Typography as="h3" variant="header-lg">
          {i18n._(merchantRegistrationStepTitles().step3)}
        </Typography>
      </div>
      <div className="flex flex-col gap-8 px-4 py-6 sm:p-8">
        {showPDF && (
          <ContentCard
            childrenWrapperClassName={cn('flex max-h-96 gap-4 overflow-visible')}
            type="confirm"
            title={t`Your signature has been recorded, download for your records.`}
          >
            <Button type="button" variant="primary-confirm" size="md" className="w-fit">
              <Trans>Download PDF</Trans>
            </Button>
            <Button
              onClick={() => setShowPDF(false)}
              type="button"
              variant="soft-confirm"
              size="md"
              className="w-fit"
            >
              <Trans>Dismiss</Trans>
            </Button>
          </ContentCard>
        )}
        <Typography variant="paragraph">
          <Trans>
            Merchant Application is provided by Adyen. Please fill out the the forms and submit it.
            When you are finished, this page will advance to the next step.
          </Trans>
        </Typography>
        <div className="pb-12 border-b border-gray-900/10">
          <Typography variant="header-form-section">
            <Trans>Personal Information</Trans>
          </Typography>
          <div className="grid grid-cols-1 mt-6 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Label text={t`First Name`}>
                <InputText readOnly={readonlyName} {...register('firstName', {required: true})} />
                <ValidationError isVisible={Boolean(errors.firstName)}>
                  {errors.firstName?.message}
                </ValidationError>
              </Label>
            </div>
            <div className="sm:col-span-3">
              <Label text={t`Last Name`}>
                <InputText readOnly={readonlyName} {...register('lastName', {required: true})} />
                <ValidationError isVisible={Boolean(errors.lastName)}>
                  {errors.lastName?.message}
                </ValidationError>
              </Label>
            </div>
            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  readOnly
                  name="email"
                  type="email"
                  value={user?.email}
                  className="focus:ring-primary-600 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 read-only:bg-gray-100 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-white/10">
          <Typography variant="header-form-section">
            <Trans>Business Information</Trans>
          </Typography>
          <div className="mt-6 sm:col-span-4">
            <Label text={t`Legal Business Name`}>
              <InputText {...register('legalBusinessName', {required: true})} />
              <ValidationError isVisible={Boolean(errors.legalBusinessName)}>
                {errors.legalBusinessName?.message}
              </ValidationError>
            </Label>
          </div>
          <div className="grid grid-cols-1 mt-6 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Controller
                name="legalEntityType"
                control={control}
                render={({field}) => (
                  <InputSelect
                    {...field}
                    className="mb-2"
                    label={t`Organization Type`}
                    options={[
                      {
                        id: 'organization',
                        label: t`Organization`,
                      },
                      {
                        id: 'soleProprietorship',
                        label: t`Sole Proprietorship`,
                      },
                    ]}
                    placeholder={t`Please select the value`}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.legalEntityType)}>
                {errors.legalEntityType?.message}
              </ValidationError>
            </div>
            <div className="sm:col-span-5">
              <Controller
                name="mccCode"
                control={control}
                render={({field}) => (
                  <InputSelect
                    {...field}
                    className="mb-2"
                    disabled={formDefaults.data?.industryCodes?.length === 1}
                    label={t`Industry`}
                    options={mccCodeOptions}
                    placeholder={t`Please select the industry`}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.timeZone)}>
                {errors.timeZone?.message}
              </ValidationError>
            </div>

            <div className="sm:col-span-3">
              <Controller
                name="estimatedMonthlyVolume"
                control={control}
                render={({field}) => (
                  <InputSelect
                    {...field}
                    className="mb-2"
                    label={t`Estimated Monthly Volume`}
                    options={[
                      {
                        id: '0-5000',
                        label: 'Less than $5,000',
                      },
                      {
                        id: '5001-9999',
                        label: '$5,001 - $9,999',
                      },
                      {
                        id: '10000-24999',
                        label: '$10,000 - $24,999',
                      },
                      {
                        id: '25000-49999',
                        label: '$25,000 - $49,999',
                      },
                      {
                        id: '50000-99999',
                        label: '$50,000 - $99,999',
                      },
                      {
                        id: '100000-249999',
                        label: '$100,000 - $249,999',
                      },
                      {
                        id: '250000',
                        label: '$250,000 and above',
                      },
                    ]}
                    placeholder={t`Please select the value`}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.estimatedMonthlyVolume)}>
                {errors.estimatedMonthlyVolume?.message}
              </ValidationError>
            </div>

            <div className="sm:col-span-3">
              <Controller
                name="estimatedAverageTicket"
                control={control}
                render={({field}) => (
                  <InputSelect
                    {...field}
                    className="mb-2"
                    label={t`Estimated Average Ticket`}
                    options={[
                      {
                        id: '0-99',
                        label: '$0 - $99',
                      },
                      {
                        id: '100-249',
                        label: '$100 - $249',
                      },
                      {
                        id: '250-499',
                        label: '$250 - $499',
                      },
                      {
                        id: '500-999',
                        label: '$500 - $999',
                      },
                      {
                        id: '1000',
                        label: '$1000 and above',
                      },
                    ]}
                    placeholder={t`Please select the value`}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.estimatedAverageTicket)}>
                {errors.estimatedAverageTicket?.message}
              </ValidationError>
            </div>
            <div className="sm:col-span-3">
              <Controller
                name="country"
                control={control}
                render={({field}) => (
                  <InputSelect
                    {...field}
                    className="mb-2"
                    label={t`Country`}
                    disabled={true}
                    options={[
                      {
                        id: 'US',
                        label: t`United States`,
                      },
                      {
                        id: 'CA',
                        label: t`Canada`,
                      },
                      {
                        id: 'AU',
                        label: t`Australia`,
                      },
                      {
                        id: 'GB',
                        label: t`United Kingdom`,
                      },
                      {
                        id: 'NZ',
                        label: t`New Zealand`,
                      },
                    ]}
                    placeholder={t`Please select country`}
                  />
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <Controller
                name="timeZone"
                control={control}
                render={({field}) => (
                  <InputSearchSelect
                    {...field}
                    className="mb-2"
                    label={t`Time Zone`}
                    options={timezoneOptions}
                    placeholder={t`Please select the value`}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.timeZone)}>
                {errors.timeZone?.message}
              </ValidationError>
            </div>

            <div className="col-span-full">
              <Controller
                name="businessAddress.line1"
                control={control}
                render={({field}) => (
                  <Typeahead
                    {...field}
                    onSelect={(value: string) => {
                      onSelectAddress(value, field.onChange);
                    }}
                    className="mb-2"
                    label={t`Street Address`}
                    options={predictions.map(item => ({
                      id: item.id,
                      label: item.name,
                    }))}
                    placeholder={t`Search...`}
                    onChange={handleAddressLineChange}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.businessAddress?.line1)}>
                {errors.businessAddress?.line1?.message}
              </ValidationError>
            </div>
            <div className="col-span-full">
              <Label text={t`Line 2`}>
                <InputText {...register('businessAddress.line2', {required: true})} />
                <ValidationError isVisible={Boolean(errors.businessAddress?.line2)}>
                  {errors.businessAddress?.line2?.message}
                </ValidationError>
              </Label>
            </div>
            <div className="sm:col-span-2 sm:col-start-1">
              <Label text={t`City`}>
                <InputText {...register('businessAddress.city', {required: true})} />
                <ValidationError isVisible={Boolean(errors.businessAddress?.city)}>
                  {errors.businessAddress?.city?.message}
                </ValidationError>
              </Label>
            </div>
            {formDefaults.data?.countryCode !== 'GB' && (
              <div className="sm:col-span-2">
                <Label text={t`State / Province`}>
                  <InputText {...register('businessAddress.state', {required: true})} />
                  <ValidationError isVisible={Boolean(errors.businessAddress?.state)}>
                    {errors.businessAddress?.state?.message}
                  </ValidationError>
                </Label>
              </div>
            )}

            <div className="sm:col-span-2">
              <Label text={t`ZIP / Postal code`}>
                <InputText {...register('businessAddress.postalCode', {required: true})} />
                <ValidationError isVisible={Boolean(errors.businessAddress?.postalCode)}>
                  {errors.businessAddress?.postalCode?.message}
                </ValidationError>
              </Label>
            </div>
            <div className="sm:col-span-3">
              <Label text={t`Business Phone Number`}>
                <InputPhone
                  name="businessPhoneNumber"
                  control={control}
                  defaultCountry={formDefaults.data?.countryCode}
                />
                <ValidationError isVisible={Boolean(errors.businessPhoneNumber)}>
                  {errors.businessPhoneNumber?.message}
                </ValidationError>
              </Label>
            </div>

            <div className="sm:col-span-4">
              <Label
                text={t`The store name to be shown on the shopper's bank or credit card statement and on the shopper receipt`}
              >
                <InputText {...register('shopperStatement', {required: true})} />
                <ValidationError isVisible={Boolean(errors.shopperStatement)}>
                  {errors.shopperStatement?.message}
                </ValidationError>
              </Label>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="company-website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <Trans>Company Website</Trans>
              </label>
              <div
                className={`flex mt-2 mb-2 rounded-md shadow-sm ${
                  !hasOnlinePresence ? 'opacity-30' : ''
                }`}
              >
                <span
                  className={`inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-300 rounded-l-md sm:text-sm ${
                    !hasOnlinePresence ? 'select-none' : ''
                  }`}
                >
                  https://
                </span>
                {!hasOnlinePresence ? (
                  <input
                    type="text"
                    className="select-none pointer-events-none focus:ring-primary-600 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    placeholder="www.example.com"
                  />
                ) : (
                  <input
                    type="text"
                    className="focus:ring-primary-600 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    placeholder="www.example.com"
                    {...register('webAddress', {required: true})}
                  />
                )}{' '}
              </div>
              <ValidationError isVisible={Boolean(errors.webAddress)}>
                {errors.webAddress?.message}
              </ValidationError>
              <div className="flex flex-col gap-2">
                <InputCheckbox
                  name="noOnlinePresence"
                  checked={!hasOnlinePresence}
                  onChange={() => setHasOnlinePresence(prev => !prev)}
                >
                  <Trans>Business does not have an online presence</Trans>
                </InputCheckbox>
              </div>
            </div>
            {formDefaults.data?.isOrganizationSupportEmailMissing && (
              <div className="sm:col-span-4">
                <Label text={t`Support Email`}>
                  <InputText {...register('supportEmail', {required: true})} />
                  <ValidationError isVisible={Boolean(errors.supportEmail)}>
                    {errors.supportEmail?.message}
                  </ValidationError>
                  <p className="text-xs leading-5 text-gray-500">
                    <Trans>
                      This email will be displayed to users as a place to get customer support on
                      the checkout page and email receipt.
                    </Trans>
                  </p>
                </Label>
              </div>
            )}
            {surchargeFeatureEnabled && (
              <div className="pt-6 border-t border-gray-200 col-span-full">
                <Typography variant="header-form-section">
                  <Trans>Surcharge Configuration</Trans>
                </Typography>
                <p className="mt-1 text-xs leading-5 text-gray-500">
                  The Surcharge represents an additional charge applied to the final payment amount.
                  This fee can be calculated as a percentage of the original payment amount or as a
                  flat value, and in some cases, both methods may be applied simultaneously.
                </p>
                <div className="flex gap-3 mt-6">
                  <InputCheckbox {...register('surchargeConfiguration.surchargeCreditCardEnabled')}>
                    <Trans>Cards</Trans>
                  </InputCheckbox>
                  <InputCheckbox {...register('surchargeConfiguration.surchargeAchEnabled')}>
                    <Trans>ACH</Trans>
                  </InputCheckbox>
                </div>
                {!!surchargeConfiguration?.surchargeCreditCardEnabled ||
                !!surchargeConfiguration?.surchargeAchEnabled ? (
                  <div className="grid grid-cols-1 mt-6 gap-x-6 gap-y-8 sm:grid-cols-6">
                    {!!surchargeConfiguration?.surchargeCreditCardEnabled && (
                      <>
                        <div className="sm:col-span-3">
                          <Label
                            text={t`Credit Card Percentage Fee (${0}% - ${maxCreditCardSurchargePercentageFee}%)`}
                          >
                            <InputText
                              {...register('surchargeConfiguration.creditCardPercentageFee')}
                              addonBefore="%"
                              hasError={Boolean(
                                errors.surchargeConfiguration?.creditCardPercentageFee
                              )}
                            />
                            <ValidationError
                              isVisible={Boolean(
                                errors.surchargeConfiguration?.creditCardPercentageFee
                              )}
                            >
                              {errors.surchargeConfiguration?.creditCardPercentageFee?.message}
                            </ValidationError>
                          </Label>
                        </div>
                        <div className="sm:col-span-3">
                          <Label text={t`Credit Card Flat Fee`}>
                            <InputText
                              {...register('surchargeConfiguration.creditCardFlatFee')}
                              addonBefore="$"
                              hasError={Boolean(errors.surchargeConfiguration?.creditCardFlatFee)}
                            />
                            <ValidationError
                              isVisible={Boolean(errors.surchargeConfiguration?.creditCardFlatFee)}
                            >
                              {errors.surchargeConfiguration?.creditCardFlatFee?.message}
                            </ValidationError>
                          </Label>
                        </div>
                      </>
                    )}
                    {!!surchargeConfiguration?.surchargeAchEnabled && (
                      <>
                        <div className="sm:col-span-3">
                          <Label
                            text={t`ACH Percentage Fee (${0}% - ${maxAchSurchargePercentageFee}%)`}
                          >
                            <InputText
                              {...register('surchargeConfiguration.achPercentageFee')}
                              addonBefore="%"
                              hasError={Boolean(errors.surchargeConfiguration?.achPercentageFee)}
                            />
                            <ValidationError
                              isVisible={Boolean(errors.surchargeConfiguration?.achPercentageFee)}
                            >
                              {errors.surchargeConfiguration?.achPercentageFee?.message}
                            </ValidationError>
                          </Label>
                        </div>
                        <div className="sm:col-span-3">
                          <Label text={t`ACH Flat Fee`}>
                            <InputText
                              {...register('surchargeConfiguration.achFlatFee')}
                              addonBefore="$"
                              hasError={Boolean(errors.surchargeConfiguration?.achFlatFee)}
                            />
                            <ValidationError
                              isVisible={Boolean(errors.surchargeConfiguration?.achFlatFee)}
                            >
                              {errors.surchargeConfiguration?.achFlatFee?.message}
                            </ValidationError>
                          </Label>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  false
                )}
              </div>
            )}
            {surchargeViaAdditionalCommissionFeatureEnabled && (
              <div className="pt-6 border-t border-gray-200 col-span-full">
                <Typography variant="header-form-section">
                  <Trans>Surcharge Configuration</Trans>
                </Typography>
                <p className="mt-1 text-xs leading-5 text-gray-500">
                  The Surcharge represents an additional charge applied to the final payment amount.
                  This fee can be calculated as a percentage of the original payment amount or as a
                  flat value, and in some cases, both methods may be applied simultaneously.
                </p>
                <div className="grid grid-cols-1 mt-6 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <Label
                      text={t`Credit Card Percentage Fee (${0}% - ${maxCreditCardSurchargePercentageFee}%)`}
                    >
                      <InputText
                        {...register(
                          'surchargeConfigurationViaAdditionalCommission.creditCardPercentageFee'
                        )}
                        addonBefore="%"
                        hasError={Boolean(
                          errors.surchargeConfigurationViaAdditionalCommission
                            ?.creditCardPercentageFee
                        )}
                      />
                      <ValidationError
                        isVisible={Boolean(
                          errors.surchargeConfigurationViaAdditionalCommission
                            ?.creditCardPercentageFee
                        )}
                      >
                        {
                          errors.surchargeConfigurationViaAdditionalCommission
                            ?.creditCardPercentageFee?.message
                        }
                      </ValidationError>
                    </Label>
                  </div>
                  <div className="sm:col-span-3">
                    <Label text={t`Credit Card Flat Fee`}>
                      <InputText
                        {...register(
                          'surchargeConfigurationViaAdditionalCommission.creditCardFlatFee'
                        )}
                        addonBefore="$"
                        hasError={Boolean(
                          errors.surchargeConfigurationViaAdditionalCommission?.creditCardFlatFee
                        )}
                      />
                      <ValidationError
                        isVisible={Boolean(
                          errors.surchargeConfigurationViaAdditionalCommission?.creditCardFlatFee
                        )}
                      >
                        {
                          errors.surchargeConfigurationViaAdditionalCommission?.creditCardFlatFee
                            ?.message
                        }
                      </ValidationError>
                    </Label>
                  </div>
                  <div className="sm:col-span-3">
                    <Label text={t`Interac Flat Fee`}>
                      <InputText
                        {...register(
                          'surchargeConfigurationViaAdditionalCommission.interacFlatFee'
                        )}
                        addonBefore="$"
                        hasError={Boolean(
                          errors.surchargeConfigurationViaAdditionalCommission?.interacFlatFee
                        )}
                      />
                      <ValidationError
                        isVisible={Boolean(
                          errors.surchargeConfigurationViaAdditionalCommission?.interacFlatFee
                        )}
                      >
                        {
                          errors.surchargeConfigurationViaAdditionalCommission?.interacFlatFee
                            ?.message
                        }
                      </ValidationError>
                    </Label>
                  </div>
                </div>
              </div>
            )}
            {tenant?.features?.askForDocumentsInMerchantApplication?.title &&
              tenant?.features?.askForDocumentsInMerchantApplication?.description && (
                <div className="flex flex-col sm:col-span-6 gap-y-2">
                  <UploadApplicationDocument
                    uploadedDocumentName={uploadedDocumentName}
                    registrationSessionId={registrationSessionId}
                    refetchRegistrationStatus={refetchRegistrationStatus}
                    title={tenant.features.askForDocumentsInMerchantApplication.title}
                    description={tenant.features.askForDocumentsInMerchantApplication.description}
                    supportedExtensions={supportedDocumentFileExtensions}
                  />
                  <ValidationError isVisible={Boolean(errors.uploadedDocumentName)}>
                    {errors.uploadedDocumentName?.message}
                  </ValidationError>
                </div>
              )}
          </div>
        </div>

        <div className="pt-8 border-t">
          <Typography variant="paragraph">
            <Trans>
              For the next step, you will be redirected to the Merchant Application (provided by
              Adyen).{' '}
            </Trans>
          </Typography>
          <Typography variant="header-form-section" className="mt-12">
            <Trans>To complete registration, you will need the following:</Trans>
          </Typography>
          <Typography variant="paragraph" className="flex items-center mt-5">
            <Trans>
              <CheckIcon className="w-6 h-6 mr-2 text-gray-700" /> Image of a government issued form
              of identification, such as your driver's license or passport.
            </Trans>
          </Typography>
          <Typography variant="paragraph" className="flex items-center mt-5">
            <Trans>
              <CheckIcon className="w-6 h-6 mr-2 text-gray-700" />
              SSN & Date of Birth of all individuals who directly or indirectly own 25% or more of
              the company.
            </Trans>
          </Typography>
          <Typography variant="paragraph" className="flex items-center mt-5">
            <Trans>
              <CheckIcon className="w-6 h-6 mr-2 text-gray-700" />
              Bank account information for settlement of funds. Federal Tax ID (EIN/TIN).
            </Trans>
          </Typography>
        </div>
      </div>
      <div className="flex items-center justify-end p-4 gap-x-6 sm:px-8">
        <Button
          disabled={signUpMutation.isLoading}
          isLoading={signUpMutation.isLoading}
          className="items-center w-full gap-1"
          size="xl"
          type="submit"
        >
          <Trans>Launch Merchant App</Trans>
          <ArrowUpRightIcon className="h-4 w-4 stroke-[3px]" aria-hidden="true" />
        </Button>
      </div>
    </form>
  );
};
