import {CurrencyCode, LocaleCode, formatAmount} from '@zentact/common';
import {SkeletonLoader} from '@zentact/ui-tailwind';

export enum SummaryItemDataType {
  CURRENCY = 'currency',
  NUMBER = 'number',
  STRING = 'string',
}

export type SummaryItemPropType = {
  title: React.ReactNode;
  value?: string | number;
  type: SummaryItemDataType;
};

// todo delete and replace with new Summary component on portal dashboard
export function Summary({
  summaryItems,
  isLoading,
  locale,
  currency,
}: {
  summaryItems: SummaryItemPropType[];
  isLoading?: boolean;
  locale: LocaleCode;
  currency: CurrencyCode;
}) {
  return (
    <div className="px-4 py-1 mt-5 bg-white rounded-lg shadow md:py-5">
      <dl
        className={`grid grid-cols-1 divide-y divide-gray-200 overflow-hidden md:grid-cols-${summaryItems.length} md:divide-x md:divide-y-0`}
      >
        {summaryItems.map(({title, value, type}, i) => (
          <div key={`summary-${i}`} className="p-3">
            <SkeletonLoader
              isLoading={Boolean(isLoading)}
              className="h-3 min-h-[300px] max-w-xs ring-gray-400/5 sm:rounded-xl md:col-span-2 md:min-h-full"
            >
              <dd className="flex items-center justify-between mt-1 sm:justify-between md:justify-center lg:flex">
                <div className="flex items-center text-2xl font-semibold">
                  {type === SummaryItemDataType.CURRENCY
                    ? formatAmount(Number(value), locale, currency)
                    : value}
                </div>
              </dd>
              <div className="flex md:justify-center">
                <dt className="text-sm font-normal text-gray-600">{title}</dt>
              </div>
            </SkeletonLoader>
          </div>
        ))}
      </dl>
    </div>
  );
}
